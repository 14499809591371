import "./style.scss";
import { useState } from "react";
import Button from "components/common/Button";
const DisconnectWarningModal = (props) => {
  const onClickCancel = () => {
    props.setIsWarning(false);
  }
  const onClickDisconnect = (e) => {
    props.setIsWarning(false);
    props.handleDisconnect(e);
  }
  return (
    <div className="warning-modal">
      <div className="modal-main">
        <div className="title">Warning</div>
        <div className="description">
          If you disconnect your NFT from this
          <br/>
          pool you will sacrifice all accumulated
          <br/>
          GIGS so far!
        </div>
        <div className="description">Disconnect at your own risk!</div>
        <div className="btnGroup">
        <div className="btn-cancel" onClick={()=>onClickCancel()}>
          Cancel
        </div>
        <div className="btn-disconn" onClick={onClickDisconnect}>
          Disconnect
        </div>
        </div>
      </div>
    </div>
  );
};

export default DisconnectWarningModal;
