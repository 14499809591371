import { Connection } from '@solana/web3.js';
import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import nacl from 'tweetnacl';

import { NETWORK } from '../pic/connect';

const connection = new Connection(NETWORK);
type VerifyWalletParamsType = {
  address: string;
  signedMessage?: string;
  memoTx?: string;
  msgTimestamp?: number;
};

export const verifyWallet = async (params: VerifyWalletParamsType) => {
  const address = params.address;
  const signedMessage = params.signedMessage;
  const memoTx = params.memoTx;
  const msgTimestamp = params.msgTimestamp;
  if (memoTx) {
    let tx;
    for (let i = 0; i < 10; i++) {
      const foundTx = await connection.getTransaction(memoTx as string, {
        commitment: 'confirmed',
      });

      if (foundTx) {
        tx = foundTx;
        break;
      }
      await new Promise((r) => setTimeout(r, 3000));
    }
    if (!tx) {
      throw new Error('Transaction is not found');
    }
    if (
      !tx.transaction.message.accountKeys
        .map((a) => a.toString())
        .includes(address)
    ) {
      throw new Error('Transaction is not signed by this address');
    }
  } else {
    try {
      if (!signedMessage) {
        throw new Error('Missing signed message');
      }
      const message = `Verify wallet ownership by signing this message: ${msgTimestamp}`;
      const decodedMessage = new TextEncoder().encode(message);
      const decodedSignedMesage = bs58.decode(signedMessage);
      const decodedAddress = bs58.decode(address);

      const isVerified = !!nacl.sign.detached.verify(
        decodedMessage,
        decodedSignedMesage,
        decodedAddress
      );
      if (!isVerified) {
        throw new Error('Signed message verification failed');
      }
    } catch (error) {
      console.log(error);
      throw new Error('Signed message verification failed');
    }
  }

  return { message: 'Verify wallet successed' };
};
