import React, { Fragment, useState } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { useWallet } from "providers/adapters/core/react";
import { toast } from 'react-toastify';
import {
  Connection,
  LAMPORTS_PER_SOL,
  SystemProgram,
  Transaction,
} from '@solana/web3.js';
import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import clsx from 'clsx';

import styles from './verifyWalletModal.module.scss';

import { verifyWallet } from '../../utils/verifyWallet';
import { NETWORK } from '../../pic/connect';

export default function VerifyWalletModal({
  isOpen,
  setIsOpen,
  setVerfied,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setVerfied: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isLedger, setIsLedger] = useState(false);
  const { publicKey, signMessage, signTransaction, disconnect } = useWallet();

  const onVerifyWallet = async () => {
    if (!publicKey || !signMessage) {
      toast.error('Wallet not connected');
      return;
    }

    const address = publicKey?.toBase58();

    if (!address) {
      toast.error('Wallet not connected');
      return;
    }

    const toastId = toast.loading('Verify wallet in progress...');
    try {
      const msgTimestamp = new Date().getTime();
      const message = `Verify wallet ownership by signing this message: ${msgTimestamp}`;
      const messageRaw = new TextEncoder().encode(message);
      const signedMessageRaw = await signMessage(messageRaw);
      const signedMessage = bs58.encode(signedMessageRaw);

      verifyWallet({
        address: address,
        signedMessage: signedMessage,
        msgTimestamp: msgTimestamp,
      })
        .then(() => {
          toast.update(toastId, {
            render: 'Verify wallet successed',
            type: 'success',
            autoClose: 2000,
            isLoading: false,
          });
          localStorage.setItem('verified_wallet', address);
          setVerfied(true);
        })
        .catch((ex) => {
          toast.update(toastId, {
            render: ex.message,
            type: 'error',
            autoClose: 2000,
            isLoading: false,
          });
        });
    } catch (ex) {
      toast.update(toastId, {
        render: 'Verify wallet failed',
        type: 'error',
        autoClose: 2000,
        isLoading: false,
      });
    }
  };

  const onVerifyLedgerWallet = async () => {
    if (!publicKey || !signTransaction) {
      toast.error('Wallet not connected');
      return;
    }

    const toastId = toast.loading('Verify wallet in progress...');

    try {
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: publicKey,
          lamports: 0.001 * LAMPORTS_PER_SOL,
        })
      );
      const connection = new Connection(NETWORK);
      transaction.feePayer = publicKey;
      const blockhash = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash.blockhash;

      const signed = await signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signed.serialize());
      const txRes = await connection.confirmTransaction({
        blockhash: blockhash.blockhash,
        lastValidBlockHeight: blockhash.lastValidBlockHeight,
        signature: signature,
      });

      if (txRes.value.err) {
        toast.update(toastId, {
          render:
            'Verify failed, please make sure you have enough SOL in your wallet',
        });

        return;
      }

      verifyWallet({ address: publicKey.toString(), memoTx: signature })
        .then(() => {
          toast.update(toastId, {
            render: 'Verify wallet successed',
            isLoading: false,
            autoClose: 2000,
            type: 'success',
          });
          localStorage.setItem('verified_wallet', publicKey.toString());
          setVerfied(true);
        })
        .catch((ex) => {
          toast.update(toastId, {
            render: ex.message,
            isLoading: false,
            autoClose: 2000,
            type: 'error',
          });
        });
    } catch (ex) {
      console.log(ex);
      toast.update(toastId, {
        render: 'Verify wallet failed',
        isLoading: false,
        autoClose: 2000,
        type: 'error',
      });
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  const handleVerifyWallet = async () => {
    if (isLedger) {
      onVerifyLedgerWallet();
    } else {
      onVerifyWallet();
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className={styles.dialog} onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter={styles.enter}
            enterFrom={styles.enterFrom}
            enterTo={styles.enterTo}
            leave={styles.leave}
            leaveFrom={styles.leaveFrom}
            leaveTo={styles.leaveTo}
          >
            <div className={styles.bgOpacity} />
          </Transition.Child>

          <div className={styles.dialogContent}>
            <div className={styles.dialogContentCenter}>
              <Transition.Child
                as={Fragment}
                enter={styles.enter}
                enterFrom={styles.enterFrom}
                enterTo={styles.enterTo}
                leave={styles.leave}
                leaveFrom={styles.enterTo}
                leaveTo={styles.enterFrom}
              >
                <Dialog.Panel className={styles.dialogPanel}>
                  <Dialog.Title className={styles.title}>
                    wallet connected ({publicKey?.toBase58().slice(0, 5)}...
                    {publicKey?.toBase58().slice(-5)})
                  </Dialog.Title>
                  <div className={styles.bodyText}>
                    <p>Verify wallet to prove ownership.</p>
                  </div>
                  <div className={styles.switchContainer}>
                    <Switch
                      checked={isLedger}
                      onChange={setIsLedger}
                      className={clsx(styles.switch, [
                        isLedger ? styles.switchOn : styles.switchOff,
                      ])}
                    >
                      <span className={styles.sr_only}>Use setting</span>
                      <span
                        aria-hidden="true"
                        className={clsx(styles.switchMarker, [
                          isLedger ? styles.translate_9 : styles.translateX_0,
                        ])}
                      />
                    </Switch>
                    <p>Using Ledger</p>
                  </div>
                  <div className={styles.buttonContainer}>
                    <button
                      type="button"
                      onClick={() => {
                        disconnect();
                        closeModal();
                      }}
                      className={styles.changeWalletBtn}
                    >
                      Change Wallet
                    </button>
                    <button
                      className={styles.verifyBtn}
                      type="button"
                      onClick={() => {
                        handleVerifyWallet();
                        closeModal();
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
