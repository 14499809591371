import "./style.scss";
import { useState } from "react";
import Button from "components/common/Button";
const PreventClaimModal = (props) => {
  const onClickCancel = () => {
    props.setIsClaim(false);
  };
  return (
    <div className="warning-modal">
      <div className="modal-main">
        <div className="description">
          Claims have been temporarily
          <br />
          paused until after GIGS IDO
        </div>
        <div className="btnGroup">
          <div className="btn-cancel" onClick={() => onClickCancel()}>
            OKAY
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreventClaimModal;
