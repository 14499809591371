import { PublicKey, Keypair } from "@solana/web3.js";

const BASE_URL = "https://7ih0okppo0.execute-api.us-east-1.amazonaws.com/dev/";

// const BASE_URL = "http://localhost:3000/dev/";
export async function hello() {
  // const URL = BASE_URL + 'hello';
  // const response = await fetch(BASE_URL)
  try {

    const response = await fetch(BASE_URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e);
  }
}

export async function getUsers() {
  const URL = BASE_URL + "get_users";
  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}
export async function getVersions() {
  const URL = BASE_URL + "get_versions";
  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}

export async function getLikes() {
  const URL = BASE_URL + "get_likes";
  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}
export async function getUserByName(username: string) {
  const URL = BASE_URL + "get_user_by_name?username=" + username;
  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}
export async function insertNewUser(username: string, password: string) {
  const URL = BASE_URL + `insert_new_user?username=${username}&password=${password}`;
  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}
export async function insertNewLikes(username: string, likes: number) {
  const URL = BASE_URL + `insert_new_likes?username=${username}&likes=${likes}`;

  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}
export async function updateLikes(username: string, likes: number) {
  const URL = BASE_URL + `update_likes?username=${username}&likes=${likes}`;

  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}
export async function insertNewContent(username: string, content: string, version_num: string, updated_time: number) {
  const URL = BASE_URL + `insert_new_content?username=${username}&content=${content}&version_num=${version_num}&updated_time=${updated_time}`;

  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}

export async function deleteVersionContent(content: string, version_num: string) {
  const URL = BASE_URL + `delete_content_version?content=${content}&version_num=${version_num}`;
  try {
    const response = await fetch(URL, { mode: "cors" })
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("err=", e)
  }
}

export async function insertNewContent_post(username: string, content: string, version_num: string, updated_time: number) {
  // const URL = BASE_URL + `insert_new_content?username=${username}&content=${content}&version_num=${version_num}`;
  const URL = BASE_URL + `insert_new_content_post`;
  try {
    const response = await fetch(URL, {
      method: 'POST',
      mode: "cors",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username, content: content, version_num: version_num, updated_time: updated_time })
    });
    const data = await response.json();
    return data;
  } catch (e) {
    console.log("insert content error=", e);
  }
}
