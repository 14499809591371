import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import "./style.scss";
import * as React from "react";
import { useAnchorWallet } from "providers/adapters/core/react";
import Giga_logo from "img/icons/gigadao-full-brand-cropped.png";
import Button from "components/common/Button";
import { ConnectWalletNavButton } from "components/ConnectWalletNavButton";
import * as utils from "utils/account_ownership";
import getProvider from "utils/getProvider";
import signMessage from "utils/signMessage";
import base58 from "bs58";
import * as mirror_raidgame from "pic/live_utils/mirror_raidgame_helpers"
import * as livePic from "pic/live";
import { Grid } from "react-loader-spinner";
import { useWallet } from "providers/adapters/core/react";
import { clusterPath } from "utils/url";
import { Link, NavLink } from "react-router-dom";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Registration: React.FC = (props) => {
  const wallet = useAnchorWallet();
  const { publicKey, connected, disconnect } = useWallet();

  const [profiles, setProfiles] = useState();
  const [discord_username, setDiscord_username] = useState<string>();
  const [discord_id, setDiscord_id] = useState<string>();
  const [discord_avatar, setDiscord_avatar] = useState<string>();
  const [twitter_username, setTwitter_username] = useState<string>();
  const [twitter_id, setTwitter_id] = useState<string>();
  const [twitter_avatar, setTwitter_avatar] = useState<string>();
  const SESSION_KEY = "RAIDGAME_SESSION_KEY";
  const [is_loaded, setIs_loaded] = useState(false);
  const [logined, setLogined] = useState(false);
  const [isConnectingToOwner, setIsConnectingToOwner] = useState(false);
  const [is_ledger, setLedger] = useState<boolean>(false);

  type SESSION_TYPE = {
    web3_id: string;
    discord_avatar: string;
    discord_username: string;
    discord_id: string;
    twitter_username: string;
    twitter_id: string;
    twitter_avatar: string;

  }
  const location = useLocation();

  const displayParsedError = (query: any) => {
    if (query.reason == 'duplicate_id') {
      if (query.status == 'twitter_fail') {
        alert("Authorizing twitter failed. You may have already registered this twitter account. Please unlink it  before linking a new one");
      }
      if (query.status == 'discord_fail') {
        alert("Authorizing discord failed. You may have already registered this discord account. Please unlink it  before linking a new one");
      }
    }
  }
  useEffect(() => {
    if (connected) {
      if (is_loaded) {
        setIsConnectingToOwner(false);
      } else {
        setIsConnectingToOwner(true);
      }
    }
  }, [is_loaded])

  useEffect(() => {
    (async () => {
      setIs_loaded(false);
      // initialize_localstorage();
      if (wallet) {
        setIs_loaded(false);
        setIsConnectingToOwner(true);
        setLogined(false);

        const query = queryString.parse(location.search);
        displayParsedError(query);

        await login();
        const localStorage = getLocalStorage();
        handleRegister();
        //!localStorage.web3_id : first login with new wallet
        //localStorage.web3_id != wallet.publicKey.toString() && (!localStorage.discord_username && !localStorage.twitter_username)
        //
        // if (!localStorage.web3_id || (localStorage.web3_id != wallet.publicKey.toString() && (!localStorage.discord_username && !localStorage.twitter_username))) {
        // if (!localStorage.web3_id || (localStorage.web3_id != wallet.publicKey.toString())) {
        //   // await registerWeb3id();
        //   setIs_loaded(true);
        //   setLogined(true)
        //   setIsConnectingToOwner(false);
        // }
        if (localStorage.web3_id == wallet.publicKey.toString()) {
          setLogined(true)
          setIs_loaded(true);
          setIsConnectingToOwner(false);
        }

      } else {
        // initialize_localstorage()
      }
    })();
  }, [wallet])

  const getLocalStorage = () => {
    const localState = window.localStorage.getItem(SESSION_KEY);
    return localState ? JSON.parse(localState) : '';
  }
  const handleDiscord = async () => {
    if (!wallet) {
      alert("Please connect your wallet");
    }
    if (discord_username) {
      setIs_loaded(false);
      await unlinkDiscord();
      setIs_loaded(true);
    } else if (logined) {
      setIs_loaded(false);
      await linkDiscord();
    }
  }
  const handleTwitter = async () => {
    if (!wallet) {
      alert("Please connect your wallet");
    }
    if (twitter_username) {
      setIs_loaded(false);
      await unlinkTwitter();
      setIs_loaded(true);
    } else if (logined) {
      setIs_loaded(false);
      await linkTwitter();
    }
  }

  const handleRegister = async () => {
    const localStorage = getLocalStorage();
    // if (!localStorage.web3_id || (localStorage.web3_id != wallet.publicKey.toString())) {
    if (is_ledger) {
      await registerWithsignTransaction();
    } else {
      await registerWithsignMessage();
    }
    setIs_loaded(true);
    setLogined(true)
    setIsConnectingToOwner(false);
    // } else {
    //   alert("Already registered");
    // }
  }
  const registerWithsignTransaction = async () => {
    const tx_sign_blockhash = await livePic.signTransaction(wallet);
    const tx_sign = tx_sign_blockhash["tx_sign"];
    const tx_blockhash = tx_sign_blockhash["blockhash"];
    const signatureb58 = base58.encode(tx_sign);

    const response = await mirror_raidgame.registerWithsignTransaction(wallet.publicKey.toString(), tx_blockhash, signatureb58);
    // if(!response.success){
    //   if(response.data.error.includes("DUPLICATE_WALLET_ADDRESS")){
    //     alert("You have already registered.")
    //   }
    // }
    if (response.success) {
      const session_value: SESSION_TYPE = {
        web3_id: wallet.publicKey.toString(),
        discord_avatar: '',
        discord_username: '',
        discord_id: '',
        twitter_username: '',
        twitter_id: '',
        twitter_avatar: '',
      }
      window.localStorage.setItem(SESSION_KEY, JSON.stringify(session_value));
    } else {
      alert("You may already be registered.")
    }
  }
  const handleLedger = () => {
    setLedger(!is_ledger);
  }
  const registerWithsignMessage = async () => {
    if (wallet) {
      const slot = await utils.get_latest_slot()
      const provider = getProvider();
      const signedMessage: any = await signMessage(provider, slot.toString());
      const signature = signedMessage.signature;
      const signatureb58 = base58.encode(signature);
      const response = await mirror_raidgame.registerWithsignMessage(wallet.publicKey.toString(), slot, signatureb58);
      if (response.success) {

        const session_value: SESSION_TYPE = {
          web3_id: wallet.publicKey.toString(),
          discord_avatar: '',
          discord_username: '',
          discord_id: '',
          twitter_username: '',
          twitter_id: '',
          twitter_avatar: '',
        }
        window.localStorage.setItem(SESSION_KEY, JSON.stringify(session_value));
      } else {
        alert("You may already be registered.")
      }
    }
  }
  //initialize discord username, twiter username and discord avatar
  const initialize_localstorage = () => {
    setDiscord_username('');
    setDiscord_avatar('');
    setDiscord_id('');
    setTwitter_username('');
    setTwitter_avatar('');
    setTwitter_id('');
    const session_value: SESSION_TYPE = {
      web3_id: '',
      discord_avatar: '',
      discord_username: '',
      discord_id: '',
      twitter_username: '',
      twitter_id: '',
      twitter_avatar: '',
    }
    window.localStorage.setItem(SESSION_KEY, JSON.stringify(session_value));
  }

  const login = async () => {
    if (wallet) {
      setIs_loaded(false);
      initialize_localstorage();
      const response = await mirror_raidgame.login(wallet.publicKey.toString());
      if (response.success) {
        const data = response.data;
        //discord id, username, avatar
        let discord_id = data.discord_id;
        setDiscord_id(discord_id);
        let discord_username = discord_id ? data.discord_username : '';
        setDiscord_username(discord_username);
        let discord_avatar = discord_id ? data.avatar_url : '';
        setDiscord_avatar(discord_avatar);

        //twitter id, username, pfp
        let twitter_id = data.twitter_id;
        setTwitter_id(twitter_id);

        let twitter_username = twitter_id ? data.twitter_username : '';
        setTwitter_username(twitter_username);
        let twitter_avatar = twitter_id ? data.twitter_avatar_url : '';
        setTwitter_avatar(twitter_avatar);


        const session_value: SESSION_TYPE = {
          web3_id: wallet.publicKey.toString(),
          discord_avatar: discord_avatar,
          discord_username: discord_username,
          discord_id: discord_id,
          twitter_username: twitter_username,
          twitter_id: twitter_id,
          twitter_avatar: twitter_avatar,
        }
        window.localStorage.setItem(SESSION_KEY, JSON.stringify(session_value));
      }

    }
  }
  const linkDiscord = async () => {
    if (wallet && !discord_username) {

      const response = await mirror_raidgame.link_discord(wallet.publicKey.toString());
      if (response.success) {
        const data = response.data;
        const discord_auth_link = data.discord_auth_link;
        window.location.href = discord_auth_link;
      }
      else {
        const data = response.data;
        displayErr(data.error);

      }
    }
  }

  const unlinkDiscord = async () => {
    if (wallet && discord_username) {
      const response = await mirror_raidgame.unlink_discord(wallet.publicKey.toString());
      if (response.success) {
        const data = response.data;
        const discord_auth_link = data.discord_auth_link;
        setDiscord_avatar('');
        setDiscord_username('');
        setDiscord_id('');

        const localStorage = getLocalStorage();
        const session_value: SESSION_TYPE = {
          web3_id: wallet.publicKey.toString(),
          discord_avatar: localStorage.discord_avatar,
          discord_username: localStorage.discord_username,
          discord_id: localStorage.discord_id,
          twitter_username: '',
          twitter_id: '',
          twitter_avatar: '',
        }
        window.localStorage.setItem(SESSION_KEY, JSON.stringify(session_value));
      }
      else {
        const data = response.data;
        displayErr(data.error);

      }
    }
  }
  const linkTwitter = async () => {
    if (wallet && !twitter_username) {
      const response = await mirror_raidgame.link_twitter(wallet.publicKey.toString());
      if (response.success) {
        const data = response.data;
        const twitter_link = data.twitter_auth_link;
        window.location.href = twitter_link;
      }
      else {
        const data = response.data;
        displayErr(data.error);

      }
    }
  }
  const unlinkTwitter = async () => {
    if (wallet && twitter_username) {
      const response = await mirror_raidgame.unlink_twitter(wallet.publicKey.toString());
      if (response.success) {
        const data = response.data;
        const twitter_link = data.twitter_auth_link;
        setTwitter_avatar('');
        setTwitter_username('');
        setTwitter_id('');

        const localStorage = getLocalStorage();
        const session_value: SESSION_TYPE = {
          web3_id: wallet.publicKey.toString(),
          discord_avatar: '',
          discord_username: '',
          discord_id: '',
          twitter_username: localStorage.twitter_username,
          twitter_id: localStorage.twitter_id,
          twitter_avatar: localStorage.twitter_avatar,
        }
        window.localStorage.setItem(SESSION_KEY, JSON.stringify(session_value));
      } else {
        const data = response.data;
        displayErr(data.error);

      }
    }
  }
  const onClickDashboard = () => {
    window.location.href = 'https://www.gigadao.io/maindashboard';
    // window.open("https://gigadao.io/maindashboard");
  }
  const displayErr = (err) => {
    if (err.includes("Must unlink existing discord_id before linking new one")) {
      alert("You have already resigstered this discord account. Please unlink it  before linking a new one");
    }
    if (err.includes("Must unlink existing twitter_id before linking new one")) {
      alert("You have already resigstered this twitter account. Please unlink it  before linking a new one");
    }
  }
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 300,
      }),
    },
  }));
  return (
    <div className="registration-main">
      <div className="registration-header">
        <div className="top-logo">
          <img src={Giga_logo} alt="Solana Explorer" />
        </div>
        <div className="top-nav-right">
          {isConnectingToOwner ? (
            <div className="d-none d-md-block padded-btn">
              <ConnectOwnerLoadingButton />
            </div>
          ) : ""}
          <Link to={clusterPath("/")}>
            <Button
              btn_type="common"
              btn_title="Dashboard"
            // onClick={() => onClickDashboard()}
            />
          </Link>
          <div className="switch-ledger">
            <FormGroup>
              <Stack direction="row" spacing={1} alignItems="center">
                {/* <Typography>Phantom</Typography> */}
                <IOSSwitch checked={is_ledger}
                  onChange={handleLedger}
                  inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>Ledger</Typography>
              </Stack>
            </FormGroup>
          </div>
          <ConnectWalletNavButton />
        </div>
      </div>
      <div className="registration-body">
        <div className="registration-title">
          Register
        </div>
        <div className="registration-body-content">
          <div className="registration-explanation">

            <div className="explanation-title">
              Twitter Permissions
            </div>
            <div className="explanation-description">
              <div className="description-item">
                <div className="item-bullet">&nbsp;1.&nbsp;</div>
                <div className="item-description">
                  See Tweets from your timeline (including protected Tweets) as well as your Lists and collections.
                </div>
              </div>
              <div className="description-item">
                <div className="item-bullet">&nbsp;2.&nbsp;</div>
                <div className="item-description">
                  See your Twitter profile information and account settings.
                </div>
              </div>
              <div className="description-item">
                <div className="item-bullet">&nbsp;3.&nbsp;</div>
                <div className="item-description">
                  See accounts you follow, mute, and block.
                </div>
              </div>
              <br />
            </div>
            <div className="explanation-title">
              Why we need this:
            </div>

            <div className="explanation-description">
              Twitter APIs will see raiders engagement only on the tweet posted by your community. It also allows for additional earning features such as; following other raiders and flexing your community PFP
            </div>
          </div>
          <div className="registration-auth">
            <div className="user-group">
              <div className="user-avatar-twitter">
                <img src={twitter_avatar} />
              </div>
              <div className="user-id">
                {/* {twitter_username ? 'Twitter Handle' : <>&nbsp;</>} */}
                Twitter Handle
              </div>
              <div className="user-id">
                {twitter_username ? `@${twitter_username}` : <>&nbsp;</>}
              </div>
              <div className="link-connection">
                <Button
                  btn_type="twitter-link"
                  btn_title={`${twitter_username ? "Unlink" : "Link"}`}
                  onClick={() => handleTwitter()}
                />
              </div>
            </div>
          </div>
          <div className="registration-auth">
            <div className="user-group">
              <div className="user-avatar-discord">
                <img src={discord_avatar} />
              </div>
              <div className="user-id">
                {/* {discord_username ? 'Discord ID' : <>&nbsp;</>} */}
                Discord ID
              </div>
              <div className="user-id">
                {discord_username ? discord_username : <>&nbsp;</>}
              </div>
              <div className="link-connection">
                <Button
                  btn_type="discord-link"
                  btn_title={`${discord_username ? "Unlink" : "Link"}`}
                  onClick={() => handleDiscord()}
                />
              </div>
            </div>
          </div>
          <div className="registration-explanation">
            <div className="explanation-title">
              Discord Permissions
            </div>
            <div className="explanation-description">
              <div className="description-item">
                <div className="item-bullet">&nbsp;1.&nbsp;</div>
                <div className="item-description">
                  Sees your username, avatar and banner.
                </div>
              </div>

              <br />
            </div>
            <div className="explanation-title">
              Why we need this:
            </div>

            <div className="explanation-description">
              Creates your Raider profile which includes; Last raid earned, total earned and rank
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
function ConnectOwnerLoadingButton() {
  return (
    <div className="nav-button-height">
      <Grid height="100%" />
    </div>
  );
}
export default Registration;