import { PublicKey, Keypair } from "@solana/web3.js";


// TODO fix raid geam eregistration

const BASE_URL = "https://l5c7864bhi.execute-api.us-east-1.amazonaws.com/dev/";
// const BASE_URL: string = "http://localhost:5000/dev/";


export async function initialize_new_web3_id(owner_address: String, slot: number, signature: String) {
  const URL = BASE_URL + `initialize_new_web3_id?owner_address=${owner_address}&slot=${slot}&signature=${signature}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
export async function gen_twitter_auth_link(owner_address: String) {
  const URL = BASE_URL + `get_twitter_auth_link?owner_address=${owner_address}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
export async function registerWithsignMessage(owner_address: String, slot: number, signature: String) {
  const URL = BASE_URL + `register?owner_address=${owner_address}&slot=${slot}&signature=${signature}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
export async function registerWithsignTransaction(owner_address: String, blockhash: String, signature: String) {
  const URL = BASE_URL + `register?owner_address=${owner_address}&blockhash=${blockhash}&signature=${signature}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
// export async function expired_block(owner_address: String, slot: number = 666, signature: String) {
//   const URL = BASE_URL + `register?owner_address=${owner_address}&slot=${slot}&signature=${signature}`;
//   const response = await fetch(URL, { mode: "cors" })
//   const data = await response.json();
//   return data;
// }
export async function login(owner_address: String) {
  const URL = BASE_URL + `login?owner_address=${owner_address}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
export async function link_twitter(owner_address: String) {
  const URL = BASE_URL + `link_twitter?owner_address=${owner_address}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
export async function unlink_twitter(owner_address: String) {
  const URL = BASE_URL + `unlink_twitter?owner_address=${owner_address}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
export async function link_discord(owner_address: String) {
  const URL = BASE_URL + `link_discord?owner_address=${owner_address}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
export async function unlink_discord(owner_address: String) {
  const URL = BASE_URL + `unlink_discord?owner_address=${owner_address}`;
  const response = await fetch(URL, { mode: "cors" })
  const data = await response.json();
  return data;
}
