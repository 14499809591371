import { useEffect, useState } from "react";
import "./style.scss";
import * as React from "react";
import Refresh from "img/icons/refresh_1.png";
import Plus_fill from "img/icons/plus_symbol_fill.png";
import Giga_logo from "img/icons/gigadao-full-brand-cropped.png";
import Profile from "img/icons/profile.png";
import IconButton from "components/common/IconButton";
import { ConnectWalletNavButton } from "../ConnectWalletNavButton";
import Button from "components/common/Button";
import DAODetailModal from "components/DAODetailModal";
import NewStream from "components/NewStream";
import TokenStream from "components/TokenStream";
import NewProposal from "components/NewProposal";
import NewDAO from "components/NewDAO";
import StreamCard from "components/StreamCard";
import TreasuryCard from "components/TreasuryCard";

import * as utils from "utils/account_ownership";
import getProvider from "../../utils/getProvider";
import signMessage from "../../utils/signMessage";
import base58 from "bs58";
import * as mirror_raidgame from "../../pic/live_utils/mirror_raidgame_helpers"

import { Grid } from "react-loader-spinner";
import { Link, NavLink } from "react-router-dom";
import { Keypair, PublicKey } from "@solana/web3.js";
import { useAnchorWallet, useWallet } from "providers/adapters/core/react";
import { useOwnerData } from "providers/owner";
import * as pic from "pic/pic";
import * as simPic from "pic/sim";
// import * as simPic from "../../pic/sim";
import * as livePic from "pic/live";
import axios from "axios";


import { useSelector, useDispatch } from "react-redux";
import { DaoState } from "store/DaoReducer";
import { shallowEqual } from "react-redux";
import ActiveProposal from "components/ActiveProposal";
import NewMultisigTreasury from "components/NewMultisigTreasury";
import MultisigTreausry from "components/MultisigTreasury";
import VerifyWalletModal from "components/VerifyWalletModal";
import { clusterPath } from "utils/url";

const DAODashboardV2: React.FC = (props) => {
  const { publicKey, connected, disconnect } = useWallet();
  const [verified, setVerified] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch, callConnectOwner, callDisconnectOwner } = useOwnerData();
  const [isConnectingToOwner, setIsConnectingToOwner] = useState(false);

  const [member_daos, setMemberDAOs] = useState<Array<pic.Dao>>([]);
  const [member_dao_ids, setMemberDaoIds] = useState<string[]>([]);
  const [selected_member_dao, setSelectedMemberDAO] = useState<pic.Dao>();
  const [show_modal, setShowModal] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [select_dao_id, setSelectDaoId] = useState<string>();
  const [selected_stream, setSelectedStream] = useState<undefined | any>();
  const [refresh_stream, setRefreshStream] = useState<boolean>(false);
  const [moedas, setMoedas] = useState([]);
  const [search, setSearch] = useState("Solana");
  const [num_checked, setNumChecked] = useState<number>(0);
  let num_checked_treasuries = 0;
  let sol_price = 0;

  const wallet = useAnchorWallet();
  const { owner } = useOwnerData();
  const dispatch_state = useDispatch();
  const content_title = ["Dashboard", "New Token Streams", "New Proposal", "Token Stream", "Active Proposal", "New Multisig Tresury", "Treasury"];
  const onSetDao = React.useCallback(
    (dao: pic.Dao) => dispatch_state({ type: "SET_DAO", payload: dao }),
    [dispatch_state]
  );
  const onSetDaos = React.useCallback(
    (daos: pic.Dao[]) => dispatch_state({ type: "SET_DAOs", payload: daos }),
    [dispatch_state]
  );
  const dao_store: pic.Dao = useSelector(
    (state: DaoState) => state.dao,
    shallowEqual
  );
  const daos_store: pic.Dao[] = useSelector(
    (state: DaoState) => state.daos,
    shallowEqual
  );

  useEffect(() => {
    (async () => {
      try {
        if (wallet && verified) {

          setIsConnectingToOwner(true);
          let [daos_with_stream] = await livePic.getDaos([{ ...dao_store }]);
          let streams = await livePic.checkIfStreamOnChain(wallet, {
            ...daos_with_stream,
          });
          const dao = { ...dao_store, streams: streams };
          onSetDao({ ...dao });
          setIsConnectingToOwner(false);//loading finished
          // setCheckedDisable(false);
        } else {
          callDisconnectOwner(dispatch);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [refresh_stream]);

  useEffect(() => {
    (async () => {
      try {
        if (verified) {
          //test
          //await livePic.getNativeBalance(wallet,"5duhhukfVLgKkmDXxK7bPPoQQXUUydN8zxbEkWXSXucU");
          // await livePic.wrapNativeSol(wallet,"5duhhukfVLgKkmDXxK7bPPoQQXUUydN8zxbEkWXSXucU");
          if (dao_store.address) {
            setIsConnectingToOwner(true);
            setRefresh(!refresh)
            setIsConnectingToOwner(false);//loading finished
          } else {

            setIsConnectingToOwner(true);
            // const newOwner: pic.Owner = { address: new PublicKey("GrGUgPNUHKPQ8obxmmbKKJUEru1D6uWu9fYnUuWjbXyi") };
            const newOwner: pic.Owner = { address: publicKey };
            let member_daos_promise = await livePic.getMemberDaos(
              newOwner,
              wallet
            );
            let mdis: Array<string> = [];
            let m_daos: Array<pic.Dao> = [];
            m_daos = member_daos_promise;
            setMemberDAOs(m_daos); //set daos to memberdaos but the dao has only address and streams
            onSetDaos(m_daos);
            mdis = m_daos.map((dao) => dao.dao_id);
            setMemberDaoIds(mdis);
            setSelectDaoId(mdis[0]);
            let [daos_with_stream] = await livePic.getDaos([{ ...m_daos[0] }]);
            let streams = await livePic.checkIfStreamOnChain(wallet, {
              ...daos_with_stream,
            });
            const dao = { ...m_daos[0], streams: streams };
            onSetDao({ ...dao });
            setSelectedMemberDAO({ ...dao }); //only first
            if (streams?.length > 0) {
              setSelectedStream({ ...streams[0] });
            } else {
              setSelectedStream({});
            }
            setIsConnectingToOwner(false);//loading finished
          }
        } 
        if (!connected || !verified) {
          callDisconnectOwner(dispatch);
          onSetDao({});
          setSelectedStream({});
          setSelectedMemberDAO({});
          setSelectDaoId('');
          setMemberDaoIds([]);
          setVerified(false);
          console.log('not connected');
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [connected, verified]);

  useEffect(() => {
    (async () => {
      if (connected && verified) {
        const daos = daos_store;
        setMemberDAOs(daos);
        const mdis = daos.map(dao => dao.dao_id);
        setMemberDaoIds(mdis);
        const dao = { ...dao_store }
        setSelectedMemberDAO({ ...dao });
        setSelectDaoId(dao.dao_id);
        if (dao_store.streams?.length > 0) {
          setSelectedStream({ ...dao_store.streams[0] });
        } else {
          setSelectedStream({});
        }
      } else {
        callDisconnectOwner(dispatch);
      }
    })();
  }, [refresh]);
  useEffect(() => {
    if(!publicKey)return;
    const verfiedWalletData = localStorage.getItem('verified_wallet');
    if (verfiedWalletData === publicKey?.toBase58()) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  }, [publicKey]);
  const getNumOfStakedNFTs = () => {
    let num_staked_nft = 0;
    let num_nfts = 0;
    try {
      if (owner.nfts != undefined) {

        num_nfts = owner.nfts.length;

        if (owner.nfts) {
          for (const nft of owner.nfts) {
            if (nft.stake) {
              num_staked_nft++;
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return num_staked_nft;
  }

  const num_staked_nfts = getNumOfStakedNFTs();
  const onChangeSelectMemberDAO = (event) => {
    setIsConnectingToOwner(true);
    let dao_id = event.target.value;
    setMemberDao(dao_id);
  };
  const getPercentOfStakedNft = () => {
    let percent = "0";
    try {
      if (owner.nfts != undefined) {

        const num_nfts = owner.nfts.length;
        let num_staked_nft = 0;
        if (owner.nfts) {
          for (const nft of owner.nfts) {
            if (nft.stake) {
              num_staked_nft++;
            }
          }
          percent = (num_staked_nft / num_nfts).toFixed(2);
        }
      }
    } catch (e) {
      console.log(e);
    }
    return percent;
  };
  const staked_percent_nft = getPercentOfStakedNft();
  const onCloseModeal = () => {
    setShowModal(0);
    setRefresh(!refresh);
  };

  const onClickRefresh = async () => {
    setRefresh(!refresh);
  };
  const getActiveProposalInfo = async (dao: pic.Dao) => {
    try {
      const _dao = await livePic.getDaoGovernanceFromChain(wallet, dao);
      dao.governance = _dao.governance;
    } catch (e) {
      console.log(e);
    }
  };
  const setMemberDao = async (dao_id: string) => {
    for (const dao of member_daos) {
      if (dao.dao_id == dao_id) {
        let [daos_with_stream] = await livePic.getDaos([{ ...dao }]);
        const streams = await livePic.checkIfStreamOnChain(wallet, {
          ...daos_with_stream,
        });
        const dao_tmp = { ...dao, streams: streams };
        setSelectedMemberDAO({ ...dao_tmp });
        setSelectDaoId(dao_id);
        onSetDao({ ...dao_tmp });
        if (streams?.length > 0) {
          setSelectedStream({ ...streams[0] });
        } else {
          setSelectedStream({});
        }
      }
    }
    setIsConnectingToOwner(false);
  };

  const onLaunchStaking = () => {
    window.open("https://www.gigadao.io/staking");
    // window.location.href = "https://www.gigadao.io/staking";

    return;
  };
  const onClickRegistration = () => {
    window.open("https://raidgame.gigadao.io/registration");
    return;
  };
  const onClickRaidGame = () => {
    window.open("https://raidgame.gigadao.io/");
    return;
  };
  const onLaunchProfile = () => {
    alert("Coming soon");
    return;
  };



  return (

    <div className="dashboard-main">
      <div className="dashboard-header">
        <div className="header-items-group">

          <div className="top-log">
            <img src={Giga_logo} alt="Solana Explorer" />
          </div>
          <div className="dashboard-dao-group">
            <div onClick={() => setShowModal(7)}>
              <IconButton icon_img={Plus_fill} background="unfill" />
            </div>
            <div className="select-memeberDAO">
              <select value={select_dao_id} onChange={onChangeSelectMemberDAO}>
                {member_dao_ids.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div onClick={onClickRefresh}>
              <IconButton icon_img={Refresh} background="unfill" />
            </div>
          </div>
          <div className="top-nav-right">
            {isConnectingToOwner ? (
              <div className="d-none d-md-block padded-btn">
                <ConnectOwnerLoadingButton />
              </div>
            ) : ""}
            <Button
              btn_type="common"
              btn_title="Dashboard"
              onClick={() => setShowModal(0)}
            />
            <div className="connect">
              <div>
                <VerifyWalletModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setVerfied={setVerified}
                />
                {publicKey ? (
                  !verified ? (
                    <button onClick={() => setIsOpen(true)} className="button">
                      Verify Wallet
                    </button>
                  ) : (
                    // <WalletMultiButton className={styles.connected} />
                    <ConnectWalletNavButton />
                  )
                ) : (
                  // <WalletMultiButton className={styles.button} />
                  <ConnectWalletNavButton />
                )}
              </div>
            </div>
            <div onClick={onLaunchProfile}>
              <IconButton icon_img={Profile} background="unfill" />
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-body">
        {/* <div className="dashboard-dao-group">
          <div onClick={() => setShowModal(0)}>
            <IconButton icon_img={Plus_fill} background="unfill" />
          </div>
          <div className="select-memeberDAO">
            <select value={select_dao_id} onChange={onChangeSelectMemberDAO}>
              {member_dao_ids.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div onClick={onClickRefresh}>
            <IconButton icon_img={Refresh} background="unfill" />
          </div>
        </div> */}
        <div className="body-header">
          {content_title[show_modal]}
        </div>
        <div className="dashboard-content">
          {(show_modal != 3 && show_modal != 6) ? (
            <div className="dashboard-content-center">
              <div className="dashboard-status-summary">
                <div className="dao-info-summary">
                  <div className="dao-summary-title">
                    <div className="unit-color">Treasury Value</div>
                  </div>
                  <div className="dao-summary-content">
                    {dao_store.streams?.map((stream, index) =>
                      index < 4 ? (
                        <div className="each-dao-info" key={index}>
                          <div className="dao-summary-value">
                            {stream.current_pool_amount}
                            <div className="unit-color">
                              {" "}
                              {stream.token_ticker}
                            </div>
                          </div>
                          <div className="dao-summary-percent">+100.0%</div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                </div>
                {/* <div className="dashboard-status-nft">
                  <div className="nft-title">Staking</div>
                  <div className="nft-content">
                    <div className="nft-staked">
                      <div className="nft-staked-title">
                        Number of Staked NFT
                      </div>
                      <div className="nft-staked-cnt">
                        {" "}
                        {num_staked_nfts}
                      </div>
                    </div>
                    <div className="nft-unstaked-cnt">
                      Number of Connected NFTs
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="dao-staking-councillor">
                {/* <div className="dao-raidgame-board">
                  <Link to={clusterPath("/registration")}>
                    <div className="raidgame-board-registration"
                    >
                      Registration
                    </div>
                  </Link>
                  <Link to={clusterPath("/raidgame")}>
                    <div className="raidgame-board-landingpage">
                      Raid Game
                    </div>
                  </Link>
                </div> */}
                <NavLink to={clusterPath("/dao/gigadao")} exact>
                  <div className="dao-staking">
                    Staking
                  </div>
                </NavLink>
                <div className="dao-councillor">
                  <div className="councillor-title">Councillor</div>
                  <div className="councillor-action-group">
                    <div
                      className="councillor-action-proposal"
                      onClick={() => setShowModal(4)}
                    >
                      Active Proposal
                    </div>
                    <div
                      className="councillor-action-proposal"
                      onClick={() => setShowModal(2)}
                    >
                      New Proposal
                    </div>
                    <div
                      className="councillor-action-proposal"
                      onClick={() => setShowModal(1)}
                    >
                      New Token Stream
                    </div>
                    <div
                      className="councillor-action-proposal"
                      onClick={() => setShowModal(3)}
                    >
                      Token Stream
                    </div>

                    <div
                      className="councillor-action-proposal"
                      onClick={() => setShowModal(5)}
                    >
                      New Multisig Treasury
                    </div>
                    <div
                      className="councillor-action-proposal"
                      onClick={() => setShowModal(6)}
                    >
                      Treasury
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {show_modal == 3 ? (
            <div className="stream-content-center">
              <div className="dashboard-status-summary">
                <div className="stream-info-summary">
                  <div className="stream-summary-title">
                    <div className="unit-color">Stream</div>
                  </div>
                  <div className="stream-summary-content">
                    <div className="each-dao-info">
                      <div className="stream-item">Stream Rate</div>
                      <div className="dao-summary-value">
                        {selected_stream
                          ? selected_stream.daily_stream_rate
                          : ""}
                        <div className="unit-color">
                          {" "}
                          {selected_stream ? selected_stream.token_ticker : ""}
                        </div>
                      </div>
                      {/* <div className="dao-summary-percent">&nbsp;</div> */}
                    </div>
                    <div className="each-dao-info">
                      <div className="stream-item">Total Earned</div>
                      <div className="dao-summary-value">
                        {selected_stream ? selected_stream.total_earned : ""}
                        <div className="unit-color">
                          {" "}
                          {selected_stream ? selected_stream.token_ticker : ""}
                        </div>
                      </div>
                      {/* <div className="dao-summary-percent">&nbsp;</div> */}
                    </div>
                    <div className="each-dao-info">
                      <div className="stream-item">Total Claimed</div>
                      <div className="dao-summary-value">
                        {selected_stream ? selected_stream.total_claimed : ""}
                        <div className="unit-color">
                          {" "}
                          {selected_stream ? selected_stream.token_ticker : ""}
                        </div>
                      </div>
                      {/* <div className="dao-summary-percent">&nbsp;</div> */}
                    </div>
                    <div className="each-dao-info">
                      <div className="stream-item">Current Pool Amount</div>
                      <div className="dao-summary-value">
                        {selected_stream
                          ? selected_stream.current_pool_amount
                          : ""}
                        <div className="unit-color">
                          {" "}
                          {selected_stream ? selected_stream.token_ticker : ""}
                        </div>
                      </div>
                      {/* <div className="dao-summary-percent">&nbsp;</div> */}
                    </div>
                    <div className="each-dao-info">
                      <div className="stream-item">Total NFTs Staked</div>
                      <div className="dao-summary-value">
                        {/* {selected_stream ? owner.nfts?.length : ""} */}
                        {num_staked_nfts}
                        <div className="unit-color">
                          {" "}
                          {num_staked_nfts > 1 ? "NFTs" : "NFT"}


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-content">
                <div className="single-card">
                  {dao_store.streams?.map((stream, index) =>
                    stream.daily_stream_rate > 0 ? (
                      <StreamCard
                        key={index}
                        setSelectedStream={setSelectedStream}
                        stream={stream}
                        is_selected={
                          stream.address.toString() ===
                          selected_stream?.address.toString()
                        }
                        setRefresh={setRefresh}
                        refresh={refresh}
                      />
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {show_modal == 6 ? (
            <div className="stream-content-center">
              <div className="dashboard-status-summary">
                <div className="stream-info-summary">
                  <div className="stream-summary-title">
                    <div className="unit-color">Treasury</div>
                  </div>

                  <div className="stream-summary-content">
                    {dao_store.streams?.map((stream, index) => {
                      if ((stream.daily_stream_rate == 0 || !stream.daily_stream_rate) && stream.checked) {
                        num_checked_treasuries++;
                        if (num_checked_treasuries <= 4) {
                          return (
                            <div className="each-dao-info" key={index}>
                              <div className="stream-item">{stream.name}</div>
                              <div className="dao-summary-value">
                                {stream.current_pool_amount ? stream.current_pool_amount.toFixed(2)
                                  : 0}
                                <div className="unit-color">
                                  {" "}
                                  {stream ? stream.token_ticker : ""}
                                </div>
                              </div>
                            </div>
                          )
                        }
                      }
                    })}
                    <div className="each-dao-info">
                      <div className="stream-item">Total Treasuries</div>
                      <div className="dao-summary-value">
                        {num_checked_treasuries}
                      </div>
                    </div>
                    <div className="each-dao-info">
                      <div className="stream-item">Treasury Value</div>
                      <div className="dao-summary-value">
                        {(num_checked_treasuries * sol_price).toFixed(2)}
                        <div className="unit-color">
                          &nbsp;
                          USDC
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="card-content">
                <div className="single-card">
                  {dao_store.streams?.map((stream, index) =>
                    stream.daily_stream_rate == 0 ? (
                      <TreasuryCard
                        key={index}
                        setSelectedStream={setSelectedStream}
                        stream={stream}
                        is_selected={
                          stream.address.toString() ===
                          selected_stream?.address.toString()
                        }
                        setRefreshStream={setRefreshStream}
                        refresh_stream={refresh_stream}
                        num_checked_treasuries={num_checked_treasuries}
                        setIsConnectingToOwner={setIsConnectingToOwner}
                        isConnectingToOwner={isConnectingToOwner}

                      />
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {show_modal == 7 && connected ? (
        <DAODetailModal onClick={() => setShowModal(0)}>
          <NewDAO dao={selected_member_dao} onClose={onCloseModeal} />
        </DAODetailModal>
      ) : (
        ""
      )}
      {show_modal == 1 && connected && selected_member_dao != undefined ? (
        <DAODetailModal onClick={() => setShowModal(0)}>
          <NewStream
            dao={selected_member_dao}
            onClose={onCloseModeal}
            setRefreshStream={setRefreshStream}
            refresh_stream={refresh_stream}
          />
        </DAODetailModal>
      ) : (
        ""
      )}
      {show_modal == 2 && connected ? (
        <DAODetailModal onClick={() => setShowModal(0)}>
          <NewProposal dao={selected_member_dao} onClose={onCloseModeal} />
        </DAODetailModal>
      ) : (
        ""
      )}
      {/* {show_modal == 3 && connected ? (
        <DAODetailModal onClick={() => setShowModal(0)}>
          <TokenStream dao={selected_member_dao} onClose={onCloseModeal} />
        </DAODetailModal>
      ) : (
        ""
      )} */}
      {show_modal == 4 && connected ? (
        <DAODetailModal onClick={() => setShowModal(0)}>
          <ActiveProposal dao={selected_member_dao} onClose={onCloseModeal} />
        </DAODetailModal>
      ) : (
        ""
      )}
      {show_modal == 5 && connected ? (
        <DAODetailModal onClick={() => setShowModal(0)}>
          <NewMultisigTreasury
            dao={selected_member_dao}
            onClose={onCloseModeal}
          />
        </DAODetailModal>
      ) : (
        ""
      )}
      {/* {show_modal == 6 && connected ? (
        <DAODetailModal onClick={() => setShowModal(0)}>
          <MultisigTreausry dao={selected_member_dao} onClose={onCloseModeal} />
        </DAODetailModal>
      ) : (
        ""
      )} */}

    </div>
  );
};

function ConnectOwnerLoadingButton() {
  return (
    <div className="nav-button-height">
      <Grid height="100%" />
    </div>
  );
}

export default DAODashboardV2;
