import "./style.scss";
import Button from "components/common/Button";
import Giga_logo from "img/icons/gigadao-full-brand-cropped.png";
import Profile from "img/icons/profile.png";
import { ConnectWalletNavButton } from "../ConnectWalletNavButton";
import IconButton from "components/common/IconButton";
import { Link, NavLink } from "react-router-dom";
import { clusterPath } from "utils/url";
import { useEffect, useState } from "react";
import { useWallet } from "providers/adapters/core/react";
import VerifyWalletModal from "components/VerifyWalletModal";

const NavHeader: React.FC = (props) => {
  const [verified, setVerified] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const { publicKey } = useWallet();
  const onLaunchProfile = () => {
    alert("Coming soon");
    return;
  };
  useEffect(() => {
    // const verfiedWalletData = localStorage.getItem('verified_wallet');

    // if (verfiedWalletData === publicKey?.toBase58()) {
    //   setVerified(true);
    // } else {
    //   setVerified(false);
    // }
    console.log('wallet: ', publicKey.toString());
  }, [publicKey]);

  return (
    <div className="nav-header">
      <div className="top-log">
        <img src={Giga_logo} alt="Solana Explorer" />
      </div>
      <div className="top-nav-right">
        <NavLink to={clusterPath("/maindashboard")} exact>
          <Button btn_type="common" btn_title="Dashboard" />
        </NavLink>
        {/* <div className="connect">
          <div>
            <VerifyWalletModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setVerfied={setVerified}
            />
            {publicKey ? (
              !verified ? (
                <button onClick={() => setIsOpen(true)} className="button">
                  Verify Wallet
                </button>
              ) : (
                // <WalletMultiButton className={styles.connected} />
                <ConnectWalletNavButton />
              )
            ) : (
              // <WalletMultiButton className={styles.button} />
              <ConnectWalletNavButton />
            )}
          </div>
        </div> */}
        <div onClick={onLaunchProfile}>
          <IconButton icon_img={Profile} background="unfill" />
        </div>
      </div>
    </div>
  );
};
export default NavHeader;
