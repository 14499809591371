

const daoAddressMap = {
    gigadao: '3cAPhAmd1Ai84WuwjEDAefvaecPaoJwiS7tY3ifSvC2q',
    fatdao: 'H4L8HhHxLJ2bV62jmsKzNKb4JakuCYbSFtBM48wSpcyp',
    talldao: '8AiL3hvSQZocZqv2FAdhaJKpAEL8NoBjobe5B1udsWbY',
    kaijureborn: '48L86TNDMw1nAJ868nisjBW16wy5PVVveRo4SPewFFtP',
    apesbvipclub: '41r44P56npFEUNZUF7Pfp4NxBx7BP7ETtSnJQpYZkYTs',
    spacerunners: '6hhWmJma1GruCSGyjamSJWhhryHLiGVycZ85rRG8JT6o',
    bokungeckos: 'BsyzeU5M6yuXoZPp79DWFG9H5r6M98LxcXPRpNvTEFmZ',
    nonanasolana: 'CY1MbKcWnGtAjvXDDDeaskWiC8aDjhbgHkk81e3C5eZv',
    dilltheseal: 'Ack91U3Dja5iGXjfAjut4dqz8mmisH5igJm2p71ZX3Tp',
    skellygangnft: 'AbFRcVDxKmDTDGwkb5tJsoQeyRKZvD1QoZAnpjyEE8C',
    thugmoknkez: '48FHCAY7cjpUr25vJmewvrhcabi4XRfrphwqp61zZM66',
    solanamonkerejects: '7iCtTuQEFjc9QhA2y5PbfCyjAxEmVapsmbhJyooX5VNv',
    moshiheads: '2EJQTqc3VvEyeqFgFeEtz1gnAmw4Nq4q19dZfcAptE33',
    portalscitizencards: 'HwQRrhdoFaAZFDJA5kWTgVpuqKNRVX5KNfF9MbySB5bQ',
    bullandbears: 'DpZjZZiWJ8JeoSpa4GY2MWHkcKQm9BJp1ovDXR8ow5qu',
    soulkombatdragons: '7JSiPh9STCubBt8SmTAE96q8WkSvjiTJUjNhi1yomAbQ',
    solanamoneyboys: 'V5v63QN1g67w24kuqJprVE9Mhs2851qsRqogVybjkwi',
    alphagorillas: '2pLrtmxKXbaF6RSoE8d1xvz9LAMKToSwTkjCR6HSe8xR',
    soullesspets: '2uMSQodyucRLQef1XA1fNuCUFpxeLgRiajyhvYPpkSUJ',
    pixelracersgen1: '4WSbNqiULG744bCxuH4uNvHX11Lq66xWowrrBx1oeG4o',
    pixelracersgen1pitcrew: 'FNtcucDexTbzpbdfBv2ss6aD2oQ82Nh5zqmZ5U3HMAHx',
    royalepandasclub: 'BUd8byosoaS5CejQmzHyt6j6GaDk8vfHjZn1FgUZK6J',
    soltreez: 'BSPLxjYRErc5PheXVvrTb5Xe6N5V46K8onYu65yFe49z',
    botheredottersgoldentickets: '6mgmTnTJTzwERn3QPEm2g8c8284zTz1WrZKF3XCpxw1p',
    '888anonclub': '43zoPjoAX8H49H4G5MzGn2i8nbFty2FTBeZwjbxZ26oM',
    cryptograf: 'EPxAUMFmMV2SDN9UQMGZzuBrzr1jtUUVPnNZ1wvWicck',
    wabbits: '8CNmkBKdYQmSfFmsnjMrNMi4VQ89bgA8utMQvtd8LGyV',
    '0euvrefristgen': 'GoPhrXPQkMEkCbtwrYroiVKwHstSzbYU8vLuLGCV9HBr',
    slumcat: 'EddNWPH4pe5EXvKS9Hu4VBEZEnXNxBtS9SFJVkoCFzHP',
    pixelracersgen1engines: 'DduUH2wmvW93U6xC2iPZ7t1NWzxHhUuxTGW6vYFFofb6',
    bossbullsclub: 'ocbJnRoRWgLLGTc4WNrunYkFJvzLZQcXmNjxTKZ4Jh3',
    solcapys: '5UacQk4SqGrWdFNyciBSgYk4GWj4THQqRCd4MLapri3P',
    theinfungibles: 'BChdLeBCtCFxakDDQY8i1aSse6EBLiLAgGBwe4Q3xcpk',
    cyborgiguanas: 'GVyAbWE9bRmYy2rqEz2dBbAhfPmf7o53WSLxd5iGVVow',
    belugie: 'GFe8DyCoLKbEtf8ybi8KZPYVkiPWuo6r2WMgVc293Hx',
    solanahodlwhales: '3rt356Wes1e2CN55weYqTSsXaBXP8a31zuCg9HbT6fA6',
    suchshibas: '8TzzM5qMbz5m9zdAisUp6NXDKGoR4pjkmqeEdSbfnNrm',
    soulless: 'BL9Yph6XXbfdDBxjSg8cJXwepjSdK44wJJHP1yRWzNsd',
    yakuengineering: 'GPs3MCcqqVm32Fi2qYBcq6QabiyGKmmTDz4WgsQJ74Dr',
    boredapesolanaclub: 'DtKumZC25F8dNcTbaCFYouCXVnidWhDzd2cNoijz29DA',
    '3dmouse': 'JEDfbPXUriEkeDPjDyG7p4MbYecEY9BfHT84sexY3CxQ',
    maushesclub: '9rHKJ4roEij7Uko1zd4NTfu9kzxnAcgr9M9Ss3weeu5p',
    diamondbaepe: 'GAPnq9r1pZujy1SBYtaUGrvb3H58z38tXaTEboDWnNpX',
    'mousefacenewSS()': 'BcFpq3kjoUeEWipjPHyry8ZagsefbmtfFR9pds9MULT7',
    toonwabbits: 'CdcGz7LFpcTVDnL1NfhwPQpB1D8ubfFYVzBBuULiKUze',
    nftreesolana: '4Az1FAPPtsQ8PmFGBihLmo4Vnp6ZG2v6fV7QMFnwEUD1',
    boopiegen1n: '6uZYQwYnUS34AM4yDcahXnGsFS61vV3jYYG9GLih61eV',
    astralhouses: '2sToxDhHqBnqLubqUXP3LdVw32RS3i3Dvpae2d2Hf7WU',
    solgods: 'FQXa3EMfSroce4GLJyPTbeyRjXAvBvoNJnpHYM5E4aLw',
    pixeldrugs: 'F6Ee2VZzobPyDLvdnLkyUTaxL8ue9vHfMouuocupgqeV',
    angomon: 'RfMpFRfwU9HokRiCM6ebzEywiv5KD4kFgZ3589aenYt',
    nftabs: '8qnv7UQdp6KRo4jud59D3TQhyREy7pdA2N9kjyHzzooW',
    astralbabyapes: '9kmJqTx1NBdcoWmKTP2ZSb61Z1sRp6kMJGWPv7mJYft3',
    angrybunnyclub: 'Fiqdv9isMnW3jNCr87on1KQyiLR9Bvh6EXyQVecR5cC',
    munkhoag: '3yQFL4Xb5JT3uH1LNmCzbQZBVmYNWPQCaX62gVbng7f9',
    mutantdinobeachclub: '5LsrPf1yTfSAcAyCAaYbemA4WAqZmJLfq6mE5n5hiT6',
    thesuitesnft: 'Egq1GHjhuF3kEX1QH1d2gyPGojhS4pTnbn8PXtR3iheJ',
    psyshrooms: '2Gz6M1xWDRD4CTKFaPzUQDM1GpAPCBQxzTSWs9WQrjLL',
    sockpass: '8zsFXBEK5bEiarYPnrPmEu5f1ZuG2TKHiGsmyQ1gSS2D',
    degenlizzy: 'HUKbytvDv83XYw8BqqdDDdZWjXXyP4DMv27qQsLSFtVL',
    starbotsgame: '4dQuuxY1zFzdhcw5tb2v7fnmXivR8JGXh4w8HMRP7Fxh',
    skellies: '4aktoVbr9RWXRsPDJ1y4GuKZGTvVYTgospLwAtiT6xVz',
    zillavskong: 'GBmsZGoB9P3WscBwzGyHGdozUgw88mZ3k78Spk9jjHgM',
    magicdao: 'BjhV4oMZdJvPUKPi57f2Hqf8kjggqtiQtF4NXsc3bJCA',
    bitbirdz: '2RQdh916CpgeuiTgSXRLigY8rwy5SBWa1FZhi5Lv8tDi',
    creepygirl: 'BsS9h98YHgFEGsMew93noZ2rs2yyd2GzUnH454rpEsm7',
    citizensbysolstead: 'EkJ695yt62ZZAboGrae8YK29ntqwvgTrnp6jdsjbjchC',
    solanadquirrel: 'FQ34CVNVPPbqUR4T9Ku6VYyDwL7gzYthnBkx1PurLvoJ',
    babyapesocialclub: '4JQzGfzAsjTj8BbFEbgDxdxTZdwV9JcAKytw4qwGZatm',
    metaforge: '6U9SvidYuTjAMaL7w8jaSafe2RRKqZYuoc1DfbKR5CUH',
    niftynanas: 'EaYtq1JhYhRrXukTHd1ui2Sc8ankARn8Mjr1u6SmAwjp',
    billionairebabymeerkatsclub: '7KFc8nHuZnY5uz8tVFFUZLXUnTtjDCpFiNCPiGFHcmM3',
    chickentribe: 'HD7ip2Dvhhqp7Gjyj6KJbGPYWpahkh4G4X9D79n9P3JV',
    boopiversethehistorians: 'AvxSqHnUq9xz3oVKoXyvysm1WgLcR5KYopq8YhULXL6f',
    theoctos: '2Vu92JTio8Jwn1xcnrTGmMfQVq9iZmjPt154CBEEp38j',
    saigowabbits: 'FBVsAnVhJbAqPhtBaN2TvEFeLW1gDmdH6765FAWWuWz',
    dokanapes: 'EkL1xidgbgBau9qqMSNvYkz13mDsKiggfAayL9ZNfZ8B',
    safaridition: '7XQjLFVp9C7YBjEjeVQM4u47dyuFkETWsgbhYvL5J8d',
    geckos: '44oen8ph5RaRHJzUYZL5Fq7vEnq5jhfS3grCQRJfUxbM',
    magicpeach: '6FYSn86K76bDDNQ1o57ZLrTqnpxjJKj4oqfHwEh4TtKK',
    solsocks: '9Pm1azF8BWYMdk22f4CX2YTwA2q2cHzGVrQDgU5JKyrJ',
    elementartnft2d: '7Sm8Cs2RcpGFV1Kv8njmGuPL2hmz6hFM9SgG537BGjMV',
    proofofconsciousness: '7YCur9mysaaisUJxGDEKDD4dx6MhpNtbEewqekGH4T4C',
    pixelbands: '5jm5XBrvrQXiHN9DdYzGdeb7jWLMcXho5RASgVKESTcf',
    thefellowship: '9U3BrKebW2JD1SLf5149BdJEmfAJjTmjfdj9nn2ufY2i',
    messageparty: 'qzA1TWZLcFquBPEViQgcg7PsQ4UJa3iki7yTq154Y5p',
    boryokudragonz: 'EmCeEwyAGBs7yWQYbWcTLgUxhaqi88zre6SD7ppwrUd3',
    botheredotters: 'Eh5sMMufoPN7N9MZGupqyk6e2omfeDbuBYuSG1nCpeqR',
    rudegolems: 'FBQuY2WYshtTMWmz5QDMLdLox5qPYQDW61NsxEkMppYB',
    aiirrelevant: 'HqmX5RXL4TWbszcBcbKmMCokXdNenGwyKwtP4NwsHFAT',
    dronies: '8xNaG9w4wpQM4Pt5u9jRBPVBsRxjdiYCijM1nUHfZZzV',
    portals: 'EUWCYT3o1GSemkKEprxZfzCgxQZcMxhYYgrFCFdjxkqd',
    bestbuds: '52qUDd4qce3QrbeeYdKZZGqUF1hweM2HX6M5U8pa7DZB',
    soldoogles: 'HRNN2JtrP6hceVrAHNX9RR5RN3zHK2YcrBvxvTP6UAwR',
    catalinawhilemixer: '6ZHSCmQViQGkFZdpxaPADiBJiyLvMcL5uYTQDwjxLnMt',
    mechsofsolana: 'GTbzbD5H7qdarzxR5xyMo11N9W57dvSZFQ7qAte29CtE',
    balloonsville2: '8cPPVSKV4jADt2pu3dWc11vPVrgm6rqWNf1KYNg1GmyB',
    jellybabies: 'F68xMjKxhEKaEByoRoV5xK3YFvZXYb8h5q6fMwQWrWok',
    alpacaland: '59Wzya4a2un3xCaqZ6dVKcRK7vq5BxLRf6KiLoje4XRe',
    pinclub: '2ymouhPSVSyAfV4DF6GbaEVPT6cS8KgGwgdjNA87t69K',
    retrowolf: '6aXhxVwNjxu2trypxpxsYwbi3qRxefs88f1LxFaXmERF',
    rudedemons: '67E6hoXtTd9cLHStweeXpvNgLjXeRgSoKoRheAW12WoM',
    fancyfenchnft: 'C3Q2DW5cuUGypRzMixFepLqJLdYupNPrBEzkMY48XS6r',
    cybervillainz: 'Fc9tQMiN25ERAk8VhG8MuADb6tmn7iA7L1oP6xnFm4zX',}



export {daoAddressMap};