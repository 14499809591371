import "./style.scss";
import * as pic from "pic/pic";
import { useEffect, useState } from "react";
import * as livePic from "../../pic/live";
import { useAnchorWallet } from "providers/adapters/core/react";
import * as mirror from "pic/live_utils/mirror_helpers"

import Giga_logo from "img/icons/gigadao-full-brand-cropped.png";

const TreasuryCard: React.FC<{
  setSelectedStream;
  stream;
  is_selected;
  setRefreshStream;
  refresh_stream;
  num_checked_treasuries;
  setIsConnectingToOwner;
  isConnectingToOwner;
}> = (props) => {
  const [flag, setFlag] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [native_balance, setNativeBalance] = useState<number>(0);
  // const [sol_balance, setSolBalance] = useState<number>(0);
  const [is_native, setIsNative] = useState<boolean>(false);
  const wallet = useAnchorWallet();
  const onClickStream = (e) => {
    props.setSelectedStream(props.stream);
  };
  const onCheckboxClick = async (evt, stream: pic.Stream) => {
    props.setIsConnectingToOwner(true);
    await mirror
      .updateStream(
        stream.address,
        stream.is_active,
        stream.num_connections,
        stream.total_earned,
        stream.last_update_timestamp,
        stream.confirmed,
        !stream.checked,
      ).then((result) => {
        console.log("update stream when clicking checker of treasury card: ", result);
      })
      .catch((err) => {
        console.log("error update stream: " + err);
      });
    props.setRefreshStream(!props.refresh_stream);
  }
  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };

  const onClickStreamAddress = (stream_address) => {
    window.open(`https://solscan.io/account/${stream_address}`);
    return;
  }
  const onClickDepositeAddress = (deposite_address) => {
    window.open(`https://solscan.io/account/${deposite_address}`);
    return;
  }

  const onClickWrap = async (stream) => {
    const bal = await livePic.wrapNativeSol(wallet, stream).then(bal => {
    });
    await getBalance(stream)
  }

  const getBalance = (stream) => {
    livePic.getBalance(wallet, stream).then(bal => {
      if (bal.Native) {
        setIsNative(true);
        setNativeBalance(bal.NativeSol);
        // setSolBalance(bal.Sol);
      }
    })
  }
  useEffect(() => {
    // 
  }, [])
  return (
    <div
      className={`streamcard-main ${props.is_selected ? "stream-card-border" : ""
        }`}
      onClick={(e) => onClickStream(e)}
    >
      <div className="stream-body">
        <div className="stream-img">
          <img src={props.stream.token_image_url} alt="Image undefined" />
        </div>
        <div className="treasury-details">
          <div className="each-detail">
            <div className="detail-title">Treasury Name</div>
            <div className="detail-info">{props.stream.name}</div>
          </div>
          <div className="each-detail">
            <div className="detail-title">Stream Address</div>
            <div className="detail-info stream-address-hint " onClick={() => onClickStreamAddress(props.stream.address.toString())}>Program Account</div>
          </div>
          <div className="each-detail">
            <div className="detail-title">Deposit Address</div>
            <div className="detail-info">
              {props.stream.token_pool_address.toString()}
              &nbsp; &nbsp;

              <div className="svg-img" onClick={() => copyToClipBoard(props.stream.token_pool_address.toString())}>
                <svg aria-hidden="true" focusable="false" data-prefix="far" strokeWidth="1" data-icon="copy" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path></svg>
              </div>
            </div>
          </div>
          <div className="each-detail">
            <div className="detail-title">Current Amount</div>

            <div className="detail-info">
              <img src={props.stream.token_image_url} />
              &nbsp;
              {props.stream.current_pool_amount ? props.stream.current_pool_amount : 0}
              {getBalance(props.stream)}
              {is_native ?
                <>
                  &nbsp;
                  Native Sol:&nbsp;{native_balance.toFixed(4)}
                  &nbsp;
                  {/* {native_balance > 0.0021 ? */}
                  {native_balance > 1 ?
                    <div className="active-info">
                      <div className="reactivate-btn" onClick={() => onClickWrap(props.stream)} >
                        Sync
                      </div>
                    </div>
                    : ''}
                  {/* &nbsp;
                  Sol:&nbsp;{sol_balance.toFixed(4)} */}
                </>
                : ''}
            </div>
          </div>
          <div className="each-detail">
            <div className="detail-title">Display Treasury</div>
            <div className="detail-info">
              <div className="container">
                <div className="round">
                  <input type="checkbox"
                    id={props.stream.token_pool_address.toString()}

                    disabled={(!props.stream.checked && (props.num_checked_treasuries >= 4)) || props.isConnectingToOwner}
                    defaultChecked={props.stream.checked}
                    onClick={(evt) => onCheckboxClick(evt, props.stream)} />
                  <label htmlFor={props.stream.token_pool_address.toString()}></label>
                </div>
              </div>
            </div>

          </div>
          <div className="each-detail-right">
            <div className="treasury-transaction" onClick={() => onClickDepositeAddress(props.stream.token_pool_address.toString())}>
              Treasury Transactions&nbsp;
              <div className="treasury-transaction" >
                <div className="svg-img">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" ><path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreasuryCard;
