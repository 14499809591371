import gigadao_logo from "../img/dao-images/GigsTokenImage_black_background.png";
import {Dao} from "../pic/pic";
import {PublicKey} from "@solana/web3.js";

let verifiedDaos: Array<Dao> = [
    {
        display_name: "GigaDAO",
        dao_id: "gigadao",
        num_nfts: 1313,
        image_url: gigadao_logo,
        is_member: false,
        address: new PublicKey("4s4kQAiVYMR4iLNsUMDty5cmBgMo226ifR8eCrKS6j8d"),
    },

    {
        display_name: "OCTOS",
        dao_id: "octos",
        num_nfts: 4444,
        image_url: "https://arweave.net/eok4px117s4DEWo76Pp4zcFI0Nn8CG4p6SCDNMoXuzc",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Sleepy Dragons",
        dao_id: "sleepydragons",
        num_nfts: 2652,
        image_url: "https://www.arweave.net/0upG6DEsuDBwD84wbYlK0D4FPMJ_1Io1Dhovr17eXFg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Ded Head",
        dao_id: "dedhead",
        num_nfts: 111,
        image_url: "https://arweave.net/ufNUlx_Q8lM3p2sfHH1unYSB4iQ_9IG8N84Pv9xFE34",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Suspicious Duck",
        dao_id: "suspiciousduck",
        num_nfts: 291,
        image_url: "https://www.arweave.net/LguI9nHgjOyJqqrkBZX68SDwaVq3g36nbMZ6XsfhP6w?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pixel Racers Gen 1 Engines",
        dao_id: "pixelracersgen1engines",
        num_nfts: 2828,
        image_url: "https://www.arweave.net/U5hH7PqRaWcue0hdi0Evd5SSz77Z5PhlUD6CZxLSjKE",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "ToastedBear",
        dao_id: "toastedbear",
        num_nfts: 8170,
        image_url: "https://www.arweave.net/IPFhGxhWtPzOh2ymtSU9Lka7LpyPDgzPApdg9PH8o7U?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Alpha Gorillas",
        dao_id: "alphagorillas",
        num_nfts: 5000,
        image_url: "https://bafybeibdvuw2c5z44zwmpcf2i4cteteb55fwhjp6isttta2tqraepgo5ti.ipfs.dweb.link/1335.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Trippy Surfers",
        dao_id: "trippysurfers",
        num_nfts: 4444,
        image_url: "https://www.arweave.net/VHtCARAHPOsuP70ii_gJK-jhZUXX5186eSbrLO2009s?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Lanabots",
        dao_id: "lanabots",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/zl7KI4THB_vzwdJAWE55KaWleUV0YWWt7LxhT_1oVEY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NEONEXUS Commercial",
        dao_id: "neonexuscommercial",
        num_nfts: 2000,
        image_url: "https://arweave.net/Lai-cvMFHwdXtCRE7gk-GA_u6WRWbrjKOD0no6uS900?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Crypto Idolz - Faces",
        dao_id: "cryptoidolzfaces",
        num_nfts: 4500,
        image_url: "https://www.arweave.net/9SRwPpxztiEFe_EQYqr4V16MNTW6ZQvzhcjg49b7rMs?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Arcryptians",
        dao_id: "thearcryptians",
        num_nfts: 686,
        image_url: "https://www.arweave.net/N5TwiNnisYXQEqJgWmi-oyZEJIOQZXudo3tmCcr0cE0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Stash",
        dao_id: "stash",
        num_nfts: 10000,
        image_url: "https://arweave.net/kqMgcLpuisxxlg4OYOTalVefA3h4kCjMsW2h_sqEn3g",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Ryno",
        dao_id: "ryno",
        num_nfts: 4444,
        image_url: "https://bafybeic6x5ym7pxsle54utzx3shymhfdcfdzvwsi5g5m34hvppfr424q5e.ipfs.dweb.link?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Panda Street",
        dao_id: "pandastreet",
        num_nfts: 7777,
        image_url: "https://arweave.net/l1q2aQVFe1JO6Ua3eN6o80P98w1LW849chgkOVQmFjg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Magic Peaches",
        dao_id: "magicpeaches",
        num_nfts: 4652,
        image_url: "https://arweave.net/SLVhnJHwtBbYR2IMgFPxUTXYXuuXsb1qRNcEAeP2p_E",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Eyeballz",
        dao_id: "eyeballz",
        num_nfts: 5000,
        image_url: "https://www.arweave.net/ROugLiZ3wC8aSWrAYmQ_2Rmha-1q67VzfVOxlFF125o?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "HOAG Munk",
        dao_id: "hoagmunk",
        num_nfts: 4314,
        image_url: "https://www.arweave.net/59vED4iMBsaC1WmPd8yy7tm4QYKVcFlPk8BrXhCPdAs?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Monkeykingdom",
        dao_id: "monkeykingdom",
        num_nfts: 2222,
        image_url: "https://arweave.net/0mGSK0gvma2KqrqMmAoBz3ak1jKBuBxvd39zZictdCI",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Dokan Apes",
        dao_id: "dokanapes",
        num_nfts: 225,
        image_url: "https://www.arweave.net/QNEB96e9UZHfJcGPXDs7MD0K41x7cbJn9_4a74A8GdM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Belugies",
        dao_id: "belugies",
        num_nfts: 8000,
        image_url: "https://www.arweave.net/G_7svbddBnf9XujfMAlNn57DLOzy5qulDbBf0Q1y4ZI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Cets on Creck",
        dao_id: "cetsoncreck",
        num_nfts: 6969,
        image_url: "https://arweave.net/HfzpiTMLklkCHUOwmtZGvyaw1fP4FC1mcaT4NGfuqLE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Monks",
        dao_id: "solanamonks",
        num_nfts: 5918,
        image_url: "https://www.arweave.net/WSCn5J9wO3OOfLJMJF3qMNmiO7HLvPY4PeZwYgnXSKc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bitmon Skins Genesis",
        dao_id: "bitmonskinsgenesis",
        num_nfts: 855,
        image_url: "https://arweave.net/R230OlpXacix9BrvG4g1Yzfmhy3kM6wIKbWLhh5FXYI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Sneaky Slamberts",
        dao_id: "sneakyslamberts",
        num_nfts: 3483,
        image_url: "https://bafybeie3wqkime6fyxasvneigtncv22mqxvlytutnm6okkhu5on6rafqz4.ipfs.dweb.link/3307.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Flipper Therapy",
        dao_id: "flippertherapy",
        num_nfts: 766,
        image_url: "https://arweave.net/Jb_gIg6a_nS-XRoBrFyS-Y3strkpOnGF_HnxU7uugd4",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Baby Ape Social Club",
        dao_id: "babyapesocialclub",
        num_nfts: 4999,
        image_url: "https://sld-babyapes.s3.amazonaws.com/164.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Peppy Platypus Posse",
        dao_id: "peppyplatypusposse",
        num_nfts: 2492,
        image_url: "https://arweave.net/eJ9Z0SDlRrhuQuGAjfaUbeKeKD954FXl8s1R4vNVnD8?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "GGSG Enigma Crystals",
        dao_id: "ggsgenigmacrystals",
        num_nfts: 2602,
        image_url: "https://www.arweave.net/_TS8mjpDIYKFd717gl8cCvDYpY0ZRKN4ZlrvIB1G0Aw?ext=jpeg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Whitelist Project",
        dao_id: "thewhitelistproject",
        num_nfts: 999,
        image_url: "https://www.arweave.net/orOcnq-Ypw9l7Mqk3i2PxfpAFAZSU9DZWhfK13zMDsc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Gamerplex OG",
        dao_id: "gamerplexog",
        num_nfts: 420,
        image_url: "https://arweave.net/PqWZH4rNd0ETt2zzprXXf9GoP3F4aWu0iOUnB0v8pjE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SoulKombat SoulFighters",
        dao_id: "soulkombatsoulfighters",
        num_nfts: 150,
        image_url: "https://www.arweave.net/Od1gIS-iM_RLPmRKf8ue6beeaqNz6C1vAmINxd9wyZU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Yaku Engineering",
        dao_id: "yakuengineering",
        num_nfts: 6985,
        image_url: "https://arweave.net/QS84qE4p81fBB4YINfDOQxjYJ9-yImx6_Ki3sJ7FBnA",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Boryoku Dragonz",
        dao_id: "boryokudragonz",
        num_nfts: 1112,
        image_url: "https://arweave.net/gSI-Hkrg96Ex0gKKgKk3JwvjwOh-kd6_IC1ylOtpYa8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "MetaHelix",
        dao_id: "metahelix",
        num_nfts: 5555,
        image_url: "https://imagedelivery.net/iulGA4WFbnct7YjvBPvP3g/33792033-586c-4c1d-f35d-f5d74beec100/public?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Such Shibas",
        dao_id: "suchshibas",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/aDrfmPYbxUm7j_bXNxxF1G8WBvFbrC7BsbxlheVns5k?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solestials",
        dao_id: "solestials",
        num_nfts: 185,
        image_url: "https://www.arweave.net/dxg1xlNRfoIxAJit-_6lQxhOfHM_tCwkD25wlrPCRCk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Chicken Tribe Coops",
        dao_id: "chickentribecoops",
        num_nfts: 99,
        image_url: "https://www.arweave.net/2584nsBOiEYdUzytLcY38yb68ZjWVZypXqzEZ3ASI2U?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Baby Zilla",
        dao_id: "babyzilla",
        num_nfts: 1592,
        image_url: "https://www.arweave.net/pnnO6ljpT4CCOPg-Drg1ZakSSq7kEcYUwECd6L-X8Lc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Project Eluune",
        dao_id: "projecteluune",
        num_nfts: 3779,
        image_url: "https://www.arweave.net/l1w-Ob97xZGfboxjngdNWlTnK2KU4qLOyKlwEAFyyN8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Astral Baby Ape Club",
        dao_id: "astralbabyapeclub",
        num_nfts: 4500,
        image_url: "https://bafybeihurhlm5pfoubrvhib7rzln43b5aidremgkz4sgairxfsoucm4wyy.ipfs.dweb.link/718.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "CHOPPY",
        dao_id: "choppy",
        num_nfts: 11547,
        image_url: "https://storage.googleapis.com/fractal-launchpad-public-assets/tc-pets/PERSIAN-SAMURAI-VIKING/RARE/BOOMBO/159.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Creepy Girls",
        dao_id: "creepygirls",
        num_nfts: 1101,
        image_url: "https://www.arweave.net/i-VyjcZJ2j5tjlaLBfkzJdftKRZM0bRmToV2tx7NdxQ?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "DoodledSMB",
        dao_id: "doodledsmb",
        num_nfts: 781,
        image_url: "https://www.arweave.net/pLDcBgWi5jkG31ql11HIcPyc6fGrHvPMUtQUGMaBv7U?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Monke Babies",
        dao_id: "monkebabies",
        num_nfts: 5000,
        image_url: "https://monkebaby.s3-us-west-1.amazonaws.com/images/a545ac0a9c88529124d96ed412001d2c.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Squirrels",
        dao_id: "solanasquirrels",
        num_nfts: 1110,
        image_url: "https://www.arweave.net/S0r_mo7LJskrix1GviAMFMRcaM9MUVR5XUp7z8PK6tI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bearu",
        dao_id: "bearu",
        num_nfts: 3333,
        image_url: "https://arweave.net/PhkjBCuuia53rldXll0j2Ajs18IiBaA5QAq7hloJvKY",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Spooky BabyApes",
        dao_id: "spookybabyapes",
        num_nfts: 678,
        image_url: "https://www.arweave.net/X9pDUQFIjQY2NFCEvqTGmmWXxgEhVdWGEbRfh1-CJmQ?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Mini Juggernauts",
        dao_id: "minijuggernauts",
        num_nfts: 50,
        image_url: "https://testlaunchmynft.mypinata.cloud/ipfs/QmWfNLrkuNVVcFtQSkjLMKmuWxDtK6soRVAbfX2ezRjLyD/22.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Proof of Consciousness",
        dao_id: "proofofconsciousness",
        num_nfts: 1150,
        image_url: "https://bafybeidv53cmppvlysydiyij3vateoltroihhdzovhhe6t7sn7ka3ywcja.ipfs.dweb.link/63.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolYetis",
        dao_id: "solyetis",
        num_nfts: 8074,
        image_url: "https://www.arweave.net/iHjvLy2zVu5N4L59bqGA2eVYzM8vliUw40FPhbuWGs0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "BlackSpot",
        dao_id: "blackspot",
        num_nfts: 666,
        image_url: "https://www.arweave.net/JJ1OnkVM9g2qCEGnK2WOYFZAoroVH2RsYwaYnfgskeA?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Gangsta NFT",
        dao_id: "solanagangstanft",
        num_nfts: 1605,
        image_url: "https://arweave.net/_yl2l1XF2MZdSfj1vwZi6L67LD1QXrHC953RhAUr5c4",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Vampires of SOL",
        dao_id: "vampiresofsol",
        num_nfts: 5000,
        image_url: "https://www.arweave.net/I53vNxrqwBesBNlDnsmwKUxasSsFqpYTn1TvMc9JMgg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SoulKombat Dragons",
        dao_id: "soulkombatdragons",
        num_nfts: 1000,
        image_url: "https://www.arweave.net/XYrm_IQbNcJqhOHub5cNKmBUfTUS1Qsb7MtLnlRtLa0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Devils of the Metaverse",
        dao_id: "devilsofthemetaverse",
        num_nfts: 676,
        image_url: "https://arweave.net/-Anum0NGO8iTl_a7cTAfYPctUcHr4omULcM1Fgih3NM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Kaiju Reborn",
        dao_id: "kaijureborn",
        num_nfts: 1313,
        image_url: "https://arweave.net/RdktCi3LBIj64SzC3OrGZYmlc2_EVdW80hdnbNL2uho?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Retro Wolves",
        dao_id: "retrowolves",
        num_nfts: 1111,
        image_url: "https://monkelabs.nyc3.digitaloceanspaces.com/retrowolves/assets/9407e38738720968fc287cc44d59c857.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Visionary Studios",
        dao_id: "visionarystudios",
        num_nfts: 777,
        image_url: "https://arweave.net/liJYoNwv6oHlxnZzV4K8Du8QTsHwD0mexOwKkwfjm8I",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolRobosPets",
        dao_id: "solrobospets",
        num_nfts: 291,
        image_url: "https://arweave.net/8JBh7ZOEcXe35xb3xYNUZw1kApRZaAQ3JwAU19OztvM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Kuzuryu Ninjas",
        dao_id: "kuzuryuninjas",
        num_nfts: 223,
        image_url: "https://www.arweave.net/qq5E-Ia8koA2ZrC09aHvd5pXO4c-LICvxjbnbshbY7Q?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pixel Lordz Club",
        dao_id: "pixellordzclub",
        num_nfts: 200,
        image_url: "https://arweave.net/6itytx2rjd2hzZz9G74tSZY_T78NalzB28W9ZSCOOCE",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Mechs of Solana",
        dao_id: "mechsofsolana",
        num_nfts: 8888,
        image_url: "https://www.arweave.net/twXbPFPrI5Idd-YSzKoa94VKZwrcaRPvNbzJwLGXuBY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Apex Ducks",
        dao_id: "apexducks",
        num_nfts: 7777,
        image_url: "https://www.arweave.net/HcNbGQEACOlga8fYsOrXy-5RHLiBCPOx8_YbNSHFLcY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Aiternate Holotabs",
        dao_id: "aiternateholotabs",
        num_nfts: 6060,
        image_url: "https://bafybeifnup2l7do6aiktl26dfsuzu2iimaxbnbklub3shahhylxr7qt7ee.ipfs.dweb.link/5334.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pixel Racers Gen 1 Cars",
        dao_id: "pixelracersgen1cars",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/TP7w-ZkRNrFeTi8ievRZdT1YPZG-HUQx_Dkx5S1RU2M",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bothered Otters",
        dao_id: "botheredotters",
        num_nfts: 1870,
        image_url: "https://www.arweave.net/QA5BZ1w03WcAmfVW1Esv4UMZ1nVmLoNF2LDSzq2JMq4?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Elementals4Fusion",
        dao_id: "elementals4fusion",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/DLxYpviwv7tcQhk00HHQQiD_dF0-G7PsuyyYDjL0wWs?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Hulk Apes",
        dao_id: "hulkapes",
        num_nfts: 4000,
        image_url: "https://apeoutcasts.s3.amazonaws.com/images_hulks/912.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolClubber",
        dao_id: "solclubber",
        num_nfts: 100,
        image_url: "https://testlaunchmynft.mypinata.cloud/ipfs/QmcpM8HuoYfpzaae96ZmyuEyShHzusiXSBc1chwTBHyu47/29.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pixel Sphinx",
        dao_id: "pixelsphinx",
        num_nfts: 444,
        image_url: "https://arweave.net/Aqn3NzbnhZc8NcA0ENEf9P5H1ugdKZmY3DHPuunhe9U?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Saigo Wabbits",
        dao_id: "saigowabbits",
        num_nfts: 222,
        image_url: "https://arweave.net/JqzMcLxLQk9aDO-AVqwIwdSoUF4p7iLXSjugISgqdrE",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Sol Dragonz",
        dao_id: "soldragonz",
        num_nfts: 49,
        image_url: "https://testlaunchmynft.mypinata.cloud/ipfs/QmckwWUK5RNd64D37w8nLbrvvug1ySazBg6SZ7pFHAXRbk/36.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Chicken Tribe",
        dao_id: "chickentribe",
        num_nfts: 3415,
        image_url: "https://www.arweave.net/EtqV1At9inqHy0tpHYRajV7iAdkdQE6xYEdWs1nDeYM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solarnauts",
        dao_id: "solarnauts",
        num_nfts: 1500,
        image_url: "https://www.arweave.net/VgXhjnoxWqEwTri-xLoYQoNbHSM2lccJQq-W_nCsZLk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Metabaes",
        dao_id: "metabaes",
        num_nfts: 7888,
        image_url: "https://www.arweave.net/E4qRdxSU1VrBda3j-WOwij6OiUsPH_MIpkEJqmMAn80?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Battle For Nippon",
        dao_id: "battlefornippon",
        num_nfts: 4371,
        image_url: "https://www.arweave.net/9RRjVMbCCKqCz3m6E5ykCQ4YRf2uvfvlflAWtEyECLo?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "UNLOCK DEFI - GOLDEN TICKETS 2",
        dao_id: "unlockdefigoldentickets2",
        num_nfts: 2500,
        image_url: "https://arweave.net/WlZbCLVbA5Wty7LwovO7D4wkZLYS5SrIOVibL-EiRgM?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bitmon Creatures",
        dao_id: "bitmoncreatures",
        num_nfts: 8337,
        image_url: "https://bitmon-images.bitmon.io/5j2qLjqKztVYKWdTA3Asv5Gom69DVWGTs69AvGQBmJN.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "CorruptCatz",
        dao_id: "corruptcatz",
        num_nfts: 5684,
        image_url: "https://arweave.net/iNrojfj-fFQeBT3QMMrW_DL9ykqIapnRW4D8bHZSDGw",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Zillaz",
        dao_id: "zillaz",
        num_nfts: 2424,
        image_url: "https://www.arweave.net/QyRZDIHo6ED3iOVk-pwigWCfIuboDPqpxmzmT0r17aY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Rugmonkez",
        dao_id: "rugmonkez",
        num_nfts: 1111,
        image_url: "https://www.arweave.net/iEkZy0HeWBa1HpLMFc6ilJEjGTvxbxBvE1qrXbj0NOo?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Slimeballz",
        dao_id: "slimeballz",
        num_nfts: 253,
        image_url: "https://www.arweave.net/o-G40VuJSl7_ohLm4Yh6Xsmnjzz64hWGEdzZeRUthYQ?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Realm Kings",
        dao_id: "realmkings",
        num_nfts: 555,
        image_url: "https://arweave.net/lYjeSx5hG3AhePXRkZkZnIMRKWUcnab-vEjYFBxYW04?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Mystery Monkey",
        dao_id: "mysterymonkey",
        num_nfts: 555,
        image_url: "https://monkelabs.nyc3.digitaloceanspaces.com/mystery-monkey/images/b44147f99dae0d8dcd14a70214ba45ec.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Cops Game1",
        dao_id: "copsgame1",
        num_nfts: 10000,
        image_url: "https://copsgame.s3-us-west-1.amazonaws.com/gen3/images/f9c311c811cef5fc6fe0b5dfe0881b04.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Stratos nft",
        dao_id: "stratosnft",
        num_nfts: 538,
        image_url: "https://www.arweave.net/WWPAORKJ0Ryj5uuSgmdb3P6LljQ9bj6cN7zZNMm8VTE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Ghost Guys",
        dao_id: "ghostguys",
        num_nfts: 1687,
        image_url: "https://bafybeihpwraypmtt5fkxcqt42rv2v7fnxsfrypbx4g3barph4uretxzyoe.ipfs.dweb.link/1080.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solmushies",
        dao_id: "solmushies",
        num_nfts: 1000,
        image_url: "https://www.arweave.net/tNRde46pvqeTBGpWGYcUFRF2dJfGxN_EUCW3HaD2fx8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SOuLTools",
        dao_id: "soultools",
        num_nfts: 333,
        image_url: "https://arweave.net/P1cuzKYEoFX8RqZsLjsQ86AYQFEBlBTr88G8GKHpQmk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Entrance",
        dao_id: "entrance",
        num_nfts: 5000,
        image_url: "https://bafybeigmby6fgl3kesbhajdh6teftqiqnqqt25uyyvmnzfw5jkzctgewke.ipfs.dweb.link/0.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Crypto Idolz Buttz",
        dao_id: "cryptoidolzbuttz",
        num_nfts: 4455,
        image_url: "https://www.arweave.net/2W9RSt3hKKQkmysCkYbzW1BZouIKFEOhINN7iIRjMRA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Diamond Baepes",
        dao_id: "diamondbaepes",
        num_nfts: 2221,
        image_url: "https://arweave.net/Nm3KrSK4L9sxWICFPG5n8pPED8ntWyzbB267ZmltySo",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Boopie Gen 1",
        dao_id: "boopiegen1",
        num_nfts: 2200,
        image_url: "https://www.arweave.net/D3cfvn1r_JNPbKlzpns0PM323rM4dYn-rnFbZvjk4CE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Rude Demons",
        dao_id: "rudedemons",
        num_nfts: 2745,
        image_url: "https://arweave.net/DQxwDpyiLlz1AS5VrYmJ06YdvT7Ij3GrJc_4Ayf2Q4k",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "PixelFlapjacks",
        dao_id: "pixelflapjacks",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/t8HtH2-pA-csAXNSpTSg_9EqiSYuIdjFztJKPBagX4Q?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Soulless Pets",
        dao_id: "soullesspets",
        num_nfts: 2657,
        image_url: "https://www.arweave.net/M3T027NftCUS8-qCWRYSolc3coX961vrs7sOUJoG-H0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "RareDon",
        dao_id: "raredon",
        num_nfts: 6174,
        image_url: "https://www.arweave.net/crkg66ZKYs8y9SbQIcj0-Pm2uBtmStmYkJg2pYtozrA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Maushes Club",
        dao_id: "maushesclub",
        num_nfts: 1111,
        image_url: "https://testlaunchmynft.mypinata.cloud/ipfs/QmNw4Ntn3aEx4NBdK3RvZC3Ygd1bdePDzPYYVhTWgBRvcy/0.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Anti Social Robot Club",
        dao_id: "antisocialrobotclub",
        num_nfts: 4144,
        image_url: "https://www.arweave.net/r4GFrAAgaZ0d5B8zzpNkxkyAeBX42QZ_AvRgm9vnQAg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "PsyShrooms",
        dao_id: "psyshrooms",
        num_nfts: 1839,
        image_url: "https://arweave.net/CYv4CL3VqQ8_fuz_heySZImbkY2kn2mo1DFDZ_2aAkQ",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "888 Anon Club™",
        dao_id: "888anonclub",
        num_nfts: 888,
        image_url: "https://www.arweave.net/izBHXDrXWDl4Tu8-afeywfd6-sAQ8LUKU8LVkqiZ5jo?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Blankies",
        dao_id: "blankies",
        num_nfts: 500,
        image_url: "https://www.arweave.net/mVhWsAbklFm9y6zGFpgezMs1uhjDPSOYFHZvePgsfec?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Sigma Sharks",
        dao_id: "sigmasharks",
        num_nfts: 8908,
        image_url: "https://www.arweave.net/eATztdYGlfNGwXfkUT0_6ZaHbOaWPO5bNTUEy8gaKyk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Tripping Balls",
        dao_id: "trippingballs",
        num_nfts: 428,
        image_url: "https://www.arweave.net/kNZNj83I0CvIHyWumv5HLqLK1KoAulU8Xi5RXMcZm14?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Lost Lynx Society",
        dao_id: "lostlynxsociety",
        num_nfts: 655,
        image_url: "https://www.arweave.net/0X9koALvqCs77TJuzXXjxn_T_uvfB-GOySm-Yy__DLw?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "CyberVillainz",
        dao_id: "cybervillainz",
        num_nfts: 990,
        image_url: "https://www.arweave.net/HlSr-rT8ufgZ3kXWjQPKJ_4C0XuoxZyFZX2st9ttqYc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NEONEXUS Residential",
        dao_id: "neonexusresidential",
        num_nfts: 2000,
        image_url: "https://arweave.net/RTh1ThE-gCFAWy8n11PdBWDNEyHB7AKLgHkgR_nerpY?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "GOONEY",
        dao_id: "gooney",
        num_nfts: 5000,
        image_url: "https://bafybeiahmtpqbxydk2pyo6zhyfxi753zk6srauj2byu6amktfdoqzsvvmq.ipfs.dweb.link/316.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Cyborg Penguins",
        dao_id: "cyborgpenguins",
        num_nfts: 777,
        image_url: "https://www.arweave.net/lO9CqEKhW2-RXhz6xMvO1hektJoaMsjPo4BQ-P7TlVw?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "LegionPunks",
        dao_id: "legionpunks",
        num_nfts: 9996,
        image_url: "https://arweave.net/t32gfZt_hwBCCS75ixqt2oHwyWjzLEl3ndzPUGFzHzs",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SOLANA BATTLE ARENA - Stones",
        dao_id: "solanabattlearenastones",
        num_nfts: 2263,
        image_url: "https://www.arweave.net/DRjRi-PEmfZolwuijmvVlQqdkHWpvgpRQ6E79-_TuRA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solworms",
        dao_id: "solworms",
        num_nfts: 2174,
        image_url: "https://solworms.mypinata.cloud/ipfs/QmPMzgeyfQrWEgGc5UqwPubkU1vAEZPSJpb2edTb4W3H5D",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Ladyfolk",
        dao_id: "ladyfolk",
        num_nfts: 777,
        image_url: "https://arweave.net/woYNRDSRP2qz4cqgd7nIAGO7fOJdm3iDNHRNmIoByWw",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolanaDads",
        dao_id: "solanadads",
        num_nfts: 488,
        image_url: "https://www.arweave.net/rJkutZQlEHAmWXHmonAIoM-RmKmYU_c3xYVgf9TEUSI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Dice Casino",
        dao_id: "dicecasino",
        num_nfts: 1111,
        image_url: "https://cdn.thedicecasino.com/nft/dice/145.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "BOSS BULLS ™ Club",
        dao_id: "bossbullsclub",
        num_nfts: 9999,
        image_url: "https://www.arweave.net/xdmtw_-x3F2PPrOtTLm7Uxq91gfaIsnB3AmndgT289I?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Magic Ticket",
        dao_id: "magicticket",
        num_nfts: 23058,
        image_url: "https://bafybeihr7vi77syiup5o4avvzemhlp4v4nplpc23haderjcjkkzgah2a5u.ipfs.dweb.link/0.gif?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Angomon",
        dao_id: "angomon",
        num_nfts: 3100,
        image_url: "https://www.arweave.net/NX55xvNN85MtuTUYWJvUyAj1K5Xj_f5ok-U9frsgf9Y?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Apes VIP Club",
        dao_id: "apesvipclub",
        num_nfts: 205,
        image_url: "https://monkelabs.nyc3.digitaloceanspaces.com/apes-vip-club/images/dada2b28ca0534e8fe58f2556f515df9.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NON-FUNGIBLE BEES",
        dao_id: "nonfungiblebees",
        num_nfts: 3133,
        image_url: "https://www.arweave.net/xInHC-9gUumxjbOWTkrkXwFqLogEqVRJIbNp6XJcIV0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Metavillage",
        dao_id: "metavillage",
        num_nfts: 600,
        image_url: "https://arweave.net/06OzmKWWK9n2thDBHzsUv1bsSNviafiGJ4qOCprBqhk",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Parrot Paradise",
        dao_id: "parrotparadise",
        num_nfts: 1000,
        image_url: "https://www.arweave.net/qPSsYiykfY70jD6yrArOOcn2eyRgtw81R46LB0qQDJQ?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Club Pixsols",
        dao_id: "clubpixsols",
        num_nfts: 60,
        image_url: "https://www.arweave.net/kdHM5A4O8rChzMVzmatztwnpmtQ6lnX7jXgmQrJmEx8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "FAB PUNKs",
        dao_id: "fabpunks",
        num_nfts: 527,
        image_url: "https://www.arweave.net/tReTq39GGScRklwFsGRmoo5fFPxFaruc6XWli_tpYjM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Kamakura 鎌倉",
        dao_id: "kamakura鎌倉",
        num_nfts: 3333,
        image_url: "https://bafybeie6et5ftxuiigwxgfrcygrn44fhtyi227fwblyg6zr4zqnekhm2eq.ipfs.nftstorage.link/2653.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Lofts Business Club",
        dao_id: "theloftsbusinessclub",
        num_nfts: 5555,
        image_url: "https://arweave.net/mrdXxphNn8damR6hC5j9_nKp63sLZlr1wO5ZodWsswk",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solanapes",
        dao_id: "solanapes",
        num_nfts: 3416,
        image_url: "https://apeoutcasts.s3.amazonaws.com/images_solana/2211.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SlumCat",
        dao_id: "slumcat",
        num_nfts: 1621,
        image_url: "https://www.arweave.net/_A7P8SoDR1LX9rb1NuJ3rBCXqk7Cmt3hz7L4dvt9EIo?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Dronies",
        dao_id: "dronies",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/bfUB8er7v1m4XuxFp3aDYGUXx7wzHPYTn7sVSHOtsrE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NEOTOYS Lost Dogs",
        dao_id: "neotoyslostdogs",
        num_nfts: 699,
        image_url: "https://www.arweave.net/SUSwdvgOxIgZWX7nNIH0jan2U5oBC03TzZeZd6UXKaw?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bunny Toons",
        dao_id: "bunnytoons",
        num_nfts: 3333,
        image_url: "https://arweave.net/Jy4MdKSCVU_tJH5rQjjZBe_A8hqjqNaOSbi_8GBEVnI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Lazy Ass Doods",
        dao_id: "lazyassdoods",
        num_nfts: 767,
        image_url: "https://www.arweave.net/HgAmHzMYz8BYPixkjDL0aGYL5YCi9IkQS2rbwnUxW28?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolBearButt",
        dao_id: "solbearbutt",
        num_nfts: 444,
        image_url: "https://www.arweave.net/pFUr676d-OvjFq76zxew5AtJEWvUHbR4WU83BMEe_cs?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "2077 Girls",
        dao_id: "2077girls",
        num_nfts: 66,
        image_url: "https://testlaunchmynft.mypinata.cloud/ipfs/QmRQUHR6jgvgEMRFNAnt6TiqFVkdZp9oLf7LpPhDWjpNL5/29.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Billionaire Whale Club",
        dao_id: "billionairewhaleclub",
        num_nfts: 4298,
        image_url: "https://www.arweave.net/pWQnG3sxurgX2YyYZkdk3C8pBr9NMEgdVC-fHY23eRk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Soulless",
        dao_id: "soulless",
        num_nfts: 9999,
        image_url: "https://www.arweave.net/yrbId0tEqza4nXIny5eeCMzInBOPGW5A3dtEbveHjzg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Mutant Dino Beach Club",
        dao_id: "mutantdinobeachclub",
        num_nfts: 4041,
        image_url: "https://www.arweave.net/0lxPrmqqSjZOyVfZ2ipa_rStXwRvfcmk04BVHPVjTmU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "CoolKittens",
        dao_id: "coolkittens",
        num_nfts: 2216,
        image_url: "https://www.arweave.net/GmJXHaj7yr3XhZj2Y3ziqIe8zc-eifSurve_LgxkDW8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Doodle Boogle",
        dao_id: "doodleboogle",
        num_nfts: 1000,
        image_url: "https://www.arweave.net/zSkJx7rGmAunBJAa64rWvMFO9LzckUVip7iRg8h4DkI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bothered Otters Golden Tickets",
        dao_id: "botheredottersgoldentickets",
        num_nfts: 8888,
        image_url: "https://www.arweave.net/t43hqXfgAzEGD-cgsKcJe7pzLh2juklFbLGea7Ihs6Y?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "3D MOUSE",
        dao_id: "3dmouse",
        num_nfts: 555,
        image_url: "https://www.arweave.net/SYFQUX-Phll5rDBQm0p6T_6h8BSECYIUn2eeEGLKj34?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Wolves of Sol Street",
        dao_id: "wolvesofsolstreet",
        num_nfts: 1276,
        image_url: "https://arweave.net/I9w7pRAmQyCdSMtoHoCme2lxave8Rb8WD1X6tskotb8",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Phantasy Girls",
        dao_id: "phantasygirls",
        num_nfts: 333,
        image_url: "https://testlaunchmynft.mypinata.cloud/ipfs/QmYpk3p1nd65DcLDgF2coR9Eo8RZs4EKQLxZ6RrCAHtx58/223.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Fractal",
        dao_id: "fractal",
        num_nfts: 100000,
        image_url: "https://arweave.net/b-vUTtd3KPa5fuKPpWRM7d7RsbTUTJEBxMNbPpUMY-w?ext=jpg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Citizens by Solsteads",
        dao_id: "citizensbysolsteads",
        num_nfts: 1357,
        image_url: "https://arweave.net/RVcclPtmCqaYXYryynf_UkPGwN3NLQS7GXwHkli4LWk",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Money Boys",
        dao_id: "solanamoneyboys",
        num_nfts: 1111,
        image_url: "https://www.arweave.net/k_ciHt0fX5EY4q5URzf4e45aaXmMYhvo4cdSnMwKPKw?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "WOOFers",
        dao_id: "woofers",
        num_nfts: 5555,
        image_url: "https://www.arweave.net/G4fjZLLnfg4g7RdZiGfbj4hW0K3MHLd0vGvAHM_Wrxs?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Alder Mages",
        dao_id: "aldermages",
        num_nfts: 1666,
        image_url: "https://arweave.net/wIcLGkUocFOyZ-2uxFbnYHuf776lwdmgrbLLa8QYm3k",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Danuki Dojo",
        dao_id: "danukidojo",
        num_nfts: 6666,
        image_url: "https://arweave.net/aXRV1PbblrS9j1NdE7lU-Nbworbq5FOoDBqoNfiuxCU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Festive Oblivia",
        dao_id: "festiveoblivia",
        num_nfts: 2222,
        image_url: "https://bafybeig66d6zzzy7xqodnvkm4xbinptudgndlmbdvgtj3ixdifbdt7dwsq.ipfs.dweb.link/1311.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Naked Meerkats Beach Club",
        dao_id: "nakedmeerkatsbeachclub",
        num_nfts: 10031,
        image_url: "https://www.arweave.net/rUvcnzLgw--Exoa8AzTqM3V-s6t4RV54bXX3v3_J8Kg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolSnatchers",
        dao_id: "solsnatchers",
        num_nfts: 7996,
        image_url: "https://www.arweave.net/IqpHX7tLhqfWbX_VUlrBmGc0_nAM87DYQAOx2CWw0GQ?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "UNLOCK DEFI - GOLDEN TICKETS 3",
        dao_id: "unlockdefigoldentickets3",
        num_nfts: 13143,
        image_url: "https://arweave.net/WlZbCLVbA5Wty7LwovO7D4wkZLYS5SrIOVibL-EiRgM?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Sussy Snails",
        dao_id: "sussysnails",
        num_nfts: 2525,
        image_url: "https://www.arweave.net/mgwo_JvFz32ubMlD_TPf0pVhvbKVOxsMStGsfi2oB9k?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Superballz",
        dao_id: "superballz",
        num_nfts: 2000,
        image_url: "https://www.arweave.net/J3RSADWRY8WTuDaRrIsHg3krejVDq0bXA6XK_KVgODA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Aqua Apes",
        dao_id: "aquaapes",
        num_nfts: 5000,
        image_url: "https://www.arweave.net/YNGiLuSCh_MfJnwInMLhkMw6aBkthlHX0C4RgGYFH8g?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "CryptoCubs Mutants",
        dao_id: "cryptocubsmutants",
        num_nfts: 3936,
        image_url: "https://www.arweave.net/2bApXoecbrcXv8gZWPc3LxEOEr_ZvC1HoQ8oYGxbGD4?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Cops Game2",
        dao_id: "copsgame2",
        num_nfts: 20000,
        image_url: "https://copsgame.s3-us-west-1.amazonaws.com/gen2/images/cbb1740e16ba53f830670aa3f6cb8e84.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "solcapys",
        dao_id: "solcapys",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/kdpeiKo5Udkteg11v8AqXsDvq9G0uUuBNwRq44qdD_Y?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "BabyMenace Nft",
        dao_id: "babymenacenft",
        num_nfts: 186,
        image_url: "https://monkelabs.nyc3.digitaloceanspaces.com/babymenace-nft/BabyMenace.jpeg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Project Eluüne Eye of Eleriah",
        dao_id: "projecteluüneeyeofeleriah",
        num_nfts: 5219,
        image_url: "https://eye-of-eleriah.s3.us-west-1.amazonaws.com/d.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "WhaleSOLs",
        dao_id: "whalesols",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/wa9q-mqNGEV6ranzsea0Kp1jjNxhE-uaGguRv828NX0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SkellyGangNFT",
        dao_id: "skellygangnft",
        num_nfts: 666,
        image_url: "https://arweave.net/oaa3kfXvexHF6lYbnlgwtElZ6hlxgjU6WDCLE1oZUVQ",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolTreez",
        dao_id: "soltreez",
        num_nfts: 4465,
        image_url: "https://www.arweave.net/4TeZRFrjb_n4GA10Ymz5_E7Y229rFxzpFhorhD5ZHfs?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solaris",
        dao_id: "solaris",
        num_nfts: 5000,
        image_url: "https://arweave.net/cxd21jbOqvUpn38VQ5nkIYTI_LgLLuT70nInHGgQ8-A?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Baby Tigers",
        dao_id: "babytigers",
        num_nfts: 751,
        image_url: "https://www.arweave.net/aGM-Ab-6s5xG209HG53pqHv2dIoWgWik1O7WX9wpnUA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "OG Atadians",
        dao_id: "ogatadians",
        num_nfts: 5000,
        image_url: "https://www.arweave.net/q7HqNtnKYYxeAzDKOtnABZGFZTO2ccj66BywNP02eVY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Great Oni War",
        dao_id: "thegreatoniwar",
        num_nfts: 1500,
        image_url: "https://www.arweave.net/3uW8DcgtrFIesFdSSwLlSs85UiqHFtDbKWMD_-kNErM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bready Bunch",
        dao_id: "breadybunch",
        num_nfts: 1691,
        image_url: "https://arweave.net/0e9ftH1TKmft779TGEyMKjf4vusEHtWUKGXuToOgv8s?ext=jpg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Guild Saga Heroes",
        dao_id: "guildsagaheroes",
        num_nfts: 9900,
        image_url: "https://gldhero-production.s3.amazonaws.com/8619.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "3D Soldiers",
        dao_id: "3dsoldiers",
        num_nfts: 4879,
        image_url: "https://www.arweave.net/gM9buZM9VIUBlr1jsh-53voXoYuOsBa-T1RIEVtVZYU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Penguin Business",
        dao_id: "solanapenguinbusiness",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/qpJnhmQAAkEoRH9fOYu9a1Tj-6e74_PjvHuWN0wIpn8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Old Souls",
        dao_id: "oldsouls",
        num_nfts: 1888,
        image_url: "https://www.arweave.net/frDN-EHF_wKl4RxWOWLdMxYJIR6PjNUXvt8TYqCEen8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Cassets",
        dao_id: "cassets",
        num_nfts: 1000,
        image_url: "https://www.arweave.net/1qn6jzutbXaVccl6YoIqK9MwQkhuxGsUTzk4T4EaMjg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "0th Edition Kami",
        dao_id: "0theditionkami",
        num_nfts: 1500,
        image_url: "https://bafybeifo6hvmgiqpym7uhzgybim2wkaktbb5ezajf6svqao33cwc7rzzqq.ipfs.dweb.link/627.gif?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Surfers",
        dao_id: "solanasurfers",
        num_nfts: 4024,
        image_url: "https://www.arweave.net/sdn2cIJ0RUbKZQMKAoVvvDRBWHtAJYMb_57UaxNFL-0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Dooku Society",
        dao_id: "dookusociety",
        num_nfts: 3100,
        image_url: "https://www.arweave.net/FYlQGXfKm7Lz8hx3vTUMmZ8pVwRptyhxS9ePAvpKvBA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pin Club",
        dao_id: "pinclub",
        num_nfts: 1136,
        image_url: "https://www.arweave.net/N6st28JZLVj-ZmixYgtfHnkUewZSEnxw0vZkjkgNdoI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Cyborg Iguanas",
        dao_id: "cyborgiguanas",
        num_nfts: 2222,
        image_url: "https://www.arweave.net/wQFBvdOXucmTcijVfbbsQS1u5D2UWglrO1bIv92RnXA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolSnatchers - Hellhounds",
        dao_id: "solsnatchershellhounds",
        num_nfts: 3488,
        image_url: "https://www.arweave.net/y59zwfANwBSU-XHNufCIb5JSBmXHWjp3kMZpi9y7UCg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Ninja Ape Academy",
        dao_id: "ninjaapeacademy",
        num_nfts: 151,
        image_url: "https://www.arweave.net/WZeBCpbT_X10BSld3_4zrj7mFeOP-Hb90d1OnS8L1-4?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Secret Squirrel Association",
        dao_id: "secretsquirrelassociation",
        num_nfts: 1002,
        image_url: "https://arweave.net/w-ptNqZgb8huNB4r2gdF69t8Y5o2NH_pg2IHgjO1ik8",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "BONEWORLD",
        dao_id: "boneworld",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/GNPo1HS0vGaV3NUtlAkeDNtanLw4pY_bou5s_1Vt_gc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Hot Spring Apes",
        dao_id: "hotspringapes",
        num_nfts: 5555,
        image_url: "https://arweave.net/Z99sfMRAChPqGZWUMsLR-BsMJHGLa2n0CLZ92Iodo9k",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Almost Famous Pandas",
        dao_id: "almostfamouspandas",
        num_nfts: 8888,
        image_url: "https://bafybeifnckswplbxpmruiu4yopwtnyfbxuhpshnu4klpk537hztrtqnhme.ipfs.dweb.link/1689.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solroyalti",
        dao_id: "solroyalti",
        num_nfts: 4294,
        image_url: "https://www.arweave.net/UdApSOe-ISOJ78KWHUm4j7TtsZ7TXY4luJ6Cq4U2k3c?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NEOTOYS Floppies",
        dao_id: "neotoysfloppies",
        num_nfts: 500,
        image_url: "https://www.arweave.net/q3rqFOa3k5H3t9p5VJVx8LWPDUTtx7Md_HwKHDj95kc?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Panthers in the Metaverse",
        dao_id: "panthersinthemetaverse",
        num_nfts: 3333,
        image_url: "https://bafybeibt2w2ki5wh2ogkwwhvj7mehzteocrrp4z4ijc3yifpysnksaehtm.ipfs.dweb.link/1718.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bit Birdz",
        dao_id: "bitbirdz",
        num_nfts: 1110,
        image_url: "https://www.arweave.net/gG8Y3zhMC_ZVOZivKJ-wbaZf_akeLVnU2aS3FPbamR0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "0euvrefristgen",
        dao_id: "0euvrefristgen",
        num_nfts: 74,
        image_url: "https://www.arweave.net/mui-LvWKc0OEgG1UeOcwkUVcsnKbgnoEWHhBA3Y-DmE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "LuckyLotto",
        dao_id: "luckylotto",
        num_nfts: 1212,
        image_url: "https://arweave.net/S9CPKi3QstyiHnSwfyilJGi0Wu_QrgSA-oAxnSJvmug?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Cops Game3",
        dao_id: "copsgame3",
        num_nfts: 10000,
        image_url: "https://copsgame.s3-us-west-1.amazonaws.com/gen1/images/330eb462960abb5900115abe86501962.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Crypto Toe",
        dao_id: "cryptotoe",
        num_nfts: 703,
        image_url: "https://www.arweave.net/bQdCRDCsEpSaGgcH2GHx-qmZWzaj5sejZS4ecU5m1Xw?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Wabbits",
        dao_id: "wabbits",
        num_nfts: 2222,
        image_url: "https://www.arweave.net/ra9svPmE9JvqhB_mIt60pC6FW2baJndMSZ14TFLORt8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pixel Racers Gen 1 Pit Crew",
        dao_id: "pixelracersgen1pitcrew",
        num_nfts: 100,
        image_url: "https://www.arweave.net/AQ33xPgqBIRmIULlvPFDUMWc9VhOfqIthwo6zagebHI",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Apex Tigers",
        dao_id: "apextigers",
        num_nfts: 506,
        image_url: "https://www.arweave.net/LgpniWFpHbEKwUghXK_YCmn732YGJZiXZC1aYaGhYSc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Magic Mushies",
        dao_id: "magicmushies",
        num_nfts: 4444,
        image_url: "https://arweave.net/WbG9EvkPKh7Jjb32mjTQE14OrvIO9TJOO6_agQtFHuo",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Kitten Coup",
        dao_id: "kittencoup",
        num_nfts: 2216,
        image_url: "https://www.arweave.net/aDc1c-G1pNJDuH_RkaBeaVTTMDInR73jXvLw-IIzRoo?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Tardisol",
        dao_id: "tardisol",
        num_nfts: 1692,
        image_url: "https://www.arweave.net/cxmDK2nLbQTaMuDke_0wF468hxYOwkwdC0eC0Wg-nQc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "WAR FREAKZ",
        dao_id: "warfreakz",
        num_nfts: 317,
        image_url: "https://cdn.freakz-w4r.zone/image/55.jpg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Degen Lizzy",
        dao_id: "degenlizzy",
        num_nfts: 4033,
        image_url: "https://www.arweave.net/qF52_DKlFI9kODrSkI1LJ4ygyUDYF44PTVwSXLckKH4?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Settlers of the Metaverse",
        dao_id: "settlersofthemetaverse",
        num_nfts: 3900,
        image_url: "https://arweave.net/5SFEZ7KOrbdBIgohZ7o_bdswxJBeGediPPrg7Bltszk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolOwl",
        dao_id: "solowl",
        num_nfts: 704,
        image_url: "https://www.arweave.net/riouC7klvCM1Ik4QEirYAiup5vIzncbO49ob9ND-Jns?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Poniez",
        dao_id: "solanaponiez",
        num_nfts: 1693,
        image_url: "https://www.arweave.net/dz_hj8lKJD1ZyFtadO01X1bbxFo-sSozY0D5f989jcY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Lifinity Flares",
        dao_id: "lifinityflares",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/kLli316j8lcJBBq9cTd_xR2cNMcJWE7T5auj-CcFOd8?ext=webp",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bannurs",
        dao_id: "bannurs",
        num_nfts: 2001,
        image_url: "https://www.arweave.net/NR7DNeoQCDW6mKCzdtqkwJl_eukIQpsFF7XQfWoiJeU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Alien Universe",
        dao_id: "alienuniverse",
        num_nfts: 5555,
        image_url: "https://www.arweave.net/4-8Gt3sDsSS0yUQvXv9_pE5-1c2DLDI3wTXqEwyX6qk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Radiant Ring",
        dao_id: "radiantring",
        num_nfts: 80,
        image_url: "https://www.arweave.net/z0gFgJfSiZ6AIAgUz56fQfK-W72mZuN614F-McvnTkg?ext=jpg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Skellies",
        dao_id: "skellies",
        num_nfts: 2083,
        image_url: "https://www.arweave.net/PJMm1aFCIJPri_Qh20sptiRbALW9BhXVWTXKuba2kvI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bukkake Dragonz",
        dao_id: "bukkakedragonz",
        num_nfts: 1111,
        image_url: "https://www.arweave.net/-GKTb2k339KS0TddoiYVkZji45RF_4UmMVQ31jgHlHs?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "MOUSE FACES 1",
        dao_id: "mousefaces1",
        num_nfts: 184,
        image_url: "https://www.arweave.net/W4jDR-WCLd9c9G79R_-thyf3vkwDZguDTqIQJE76AXM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "PopsicleNFT",
        dao_id: "popsiclenft",
        num_nfts: 8888,
        image_url: "https://popsiclenft.com/assets/4bf3406c9dda8d73a48648d8080955c6ba727a645a7ff4f8f2fb3b1996c36be1.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pixel Drugs",
        dao_id: "pixeldrugs",
        num_nfts: 176,
        image_url: "https://www.arweave.net/z0orlTF4502EEbsvu-rKXmJXrZZOguqpi2lRXQrH_8c?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NEONEXUS Property Tokens",
        dao_id: "neonexuspropertytokens",
        num_nfts: 5000,
        image_url: "https://arweave.net/8WUghejOcgVvxl6AcZuMmbbmlkk70C1au1o4IQo-ohA?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Zilla vs Kong",
        dao_id: "zillavskong",
        num_nfts: 888,
        image_url: "https://www.arweave.net/63tQ5X5Xt0tmqCcWQKJ7x62NTBalmxfr3g-4k5y2HjY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Sparks",
        dao_id: "sparks",
        num_nfts: 1014,
        image_url: "https://www.arweave.net/v7ibJzr_Zbwy974JouXbk06XDmdnhHOZukymbwFrGyU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "TiasTacos",
        dao_id: "tiastacos",
        num_nfts: 1200,
        image_url: "https://www.arweave.net/aTTJ_OpTN7PNwPFK3dpMdWxCdfE1NCseVKELkQKGXFY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Chikarachimps",
        dao_id: "chikarachimps",
        num_nfts: 888,
        image_url: "https://www.arweave.net/DVTR9jiDOGLwolBvvOmK3pRxZUt5yIsqt3q91VddP6U?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SOLTigers",
        dao_id: "soltigers",
        num_nfts: 5173,
        image_url: "https://www.arweave.net/WfKDaoUHZA9Af4cWRhWnviCqUeAEs5wgkgK6bVIefCU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolSocks",
        dao_id: "solsocks",
        num_nfts: 500,
        image_url: "https://www.arweave.net/fmgCYN5ri-mhgPOjGl2BUx96LM7ifLTacgQL_9I7BLI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Soljunks",
        dao_id: "soljunks",
        num_nfts: 8888,
        image_url: "https://www.arweave.net/pFqB1hxG2cPmUH8zi_XuL2rsbuK7RrqsfJUx2_sVOLk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NFTabs",
        dao_id: "nftabs",
        num_nfts: 5199,
        image_url: "https://s3-eu-west-1.amazonaws.com/nftabs/production/mint_attempts/461/images/nft_164541712820220221-4-9oj666.webp?1645417141",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Space Runners",
        dao_id: "spacerunners",
        num_nfts: 10000,
        image_url: "https://metadata-temp-api.spacerunners.com/images/4507",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "solrobos",
        dao_id: "solrobos",
        num_nfts: 777,
        image_url: "https://www.arweave.net/Ruvhh1w8-CLsmTI-5Nnf0ENxpNyy3Uqfg8JUzwpI2Bc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Panda Social Club (Panda Fraternity Drinks)",
        dao_id: "solanapandasocialclubpandafraternitydrinks",
        num_nfts: 4129,
        image_url: "https://www.arweave.net/C6_RN-nHwLXrR82Wxd_aIRdyVBdRbfAccracJB4kx9E?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Portals",
        dao_id: "portals",
        num_nfts: 5000,
        image_url: "https://arweave.net/QPsEEJ-YpRjF35LzHWGhuknJ0tdN7n0ehjxpvpwFYmE?ext=jpg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Tiny Colony",
        dao_id: "tinycolony",
        num_nfts: 5000,
        image_url: "https://arweave.net/X9v5ULpgsm-jIWr_UA0DhctjjgPVpPLO_xmcKxe3530",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SOLANA BATTLE ARENA - Items",
        dao_id: "solanabattlearenaitems",
        num_nfts: 156,
        image_url: "https://www.arweave.net/xldXvFH533E0xoy97cFWTa8TxK2PJQIAAVrIvoPcZ6s?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Astral Houses",
        dao_id: "astralhouses",
        num_nfts: 4550,
        image_url: "https://arweave.net/d-MHka5l5-5ghKrMOMxYHpOkQ9aujZLIQePjPQiuBJs",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "MOUSE FACES 2",
        dao_id: "mousefaces2",
        num_nfts: 334,
        image_url: "https://www.arweave.net/PTwfULBqk5BFDE8B6_T94rR3j9aC0eBHb-Hwu9YkfV0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Moshiheads",
        dao_id: "moshiheads",
        num_nfts: 3000,
        image_url: "https://arweave.net/76cPQ4fiVvxJ8GXFdowN0szvcYWChhZ64Itm_5ncmzs",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pixel Outcasts",
        dao_id: "pixeloutcasts",
        num_nfts: 6778,
        image_url: "https://apeoutcasts.s3.amazonaws.com/images_pixels/423.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Wholesome Whales",
        dao_id: "wholesomewhales",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/wDHeE6hgFZxoR0tTrevziXJmCks8UuOjg2rgypgqn6o?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Islands",
        dao_id: "solanaislands",
        num_nfts: 1501,
        image_url: "https://www.arweave.net/OCAQhkeTtMFpasKDIK9JDXrTABX3nerm0lsFG34-EZU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solopoly 2.0",
        dao_id: "solopoly20",
        num_nfts: 160,
        image_url: "https://monkelabs.nyc3.digitaloceanspaces.com/solopoly/images/dcc3a6f91a6836c9887ffaa65812d60f.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Mystery Man",
        dao_id: "mysteryman",
        num_nfts: 778,
        image_url: "https://arweave.net/Ffo34E72Jt49XAM14SuaiHGm3HlX4_3q36fEkVi79gU",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SOL Grand Apez",
        dao_id: "solgrandapez",
        num_nfts: 3219,
        image_url: "https://testlaunchmynft.mypinata.cloud/ipfs/QmNywVA8bHwoyP9AB1UZoEbfiow3UrCaxoPxRzwsNbyqkn/1380.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Dog House",
        dao_id: "solanadoghouse",
        num_nfts: 3400,
        image_url: "https://www.arweave.net/ip8QxQ-IjdKUUUMwBN_TzcvxYg5Zy4ihAB8mjLJ8Gr8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolanaMoms",
        dao_id: "solanamoms",
        num_nfts: 333,
        image_url: "https://www.arweave.net/DQhftx1n6gKFJFp63IZWx_D3Vvq8wL_qlpZjWWH2NH4?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "First Verse",
        dao_id: "firstverse",
        num_nfts: 5555,
        image_url: "https://bafybeif7m4t5twoxm574qadpwbvvvlduy7r2cn7hn7xnogpjtxgl4wju4m.ipfs.dweb.link/2543.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "JellyBabies",
        dao_id: "jellybabies",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/PLiLAm5ZalEQHqDRloEsdkfOGac3WOhUW-tRKEd45jw?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Glitterflies",
        dao_id: "theglitterflies",
        num_nfts: 777,
        image_url: "https://arweave.net/V2qnlmWl9fZ9KMQgnv3w_1mElnnI2QRNoqyKKcfCJGU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Mutant Monkey Business",
        dao_id: "mutantmonkeybusiness",
        num_nfts: 5000,
        image_url: "https://mutantmonke.s3-us-west-1.amazonaws.com/images/ef9329edbfca8b266947951abaf87ef5.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Billionaire Baby Meerkats",
        dao_id: "billionairebabymeerkats",
        num_nfts: 422,
        image_url: "https://arweave.net/8hpRsm5b1pfvTe4-65-bzwRXPavN6lPXZ1V5VyXcExU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Shibatsu Club",
        dao_id: "shibatsuclub",
        num_nfts: 210,
        image_url: "https://www.arweave.net/QfrXg-h0ZQUzn7ThE3M4PR5UgwBgUX7If3nHk59a6DI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Red Panda Squad",
        dao_id: "redpandasquad",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/ypWGM3Dcyfnh5DXUWiaCFLAfppTU4BEhrUtypqQyPMI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Tower",
        dao_id: "tower",
        num_nfts: 9692,
        image_url: "https://www.arweave.net/gn18W_EEvIWjNlggO5W8Mdt5hSUptPEcpnPDcgMDTj4?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "ApexDucks Halloween",
        dao_id: "apexduckshalloween",
        num_nfts: 3333,
        image_url: "https://www.arweave.net/g6JrEgieYeXzdsVmDGZUWBdGijwfHdP5LKRsL1Iykyw?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Element Art NFT 2D",
        dao_id: "elementartnft2d",
        num_nfts: 5000,
        image_url: "https://www.arweave.net/I46GX3t1VNp43nIoc64Y-UnwHdoWBR_ltc4jydepGt8?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Debonair Degen Hawks",
        dao_id: "debonairdegenhawks",
        num_nfts: 7500,
        image_url: "https://bafybeiha2seu7pfhm73wxjpy4ijlbmdyfu7pfpoeohkzzdgyya42u7lpye.ipfs.dweb.link/3.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolSeats",
        dao_id: "solseats",
        num_nfts: 700,
        image_url: "https://arweave.net/rj0-YuvAGtvo1WNoUN2AuYafo527uvZpN46da7HIds0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Best Buds",
        dao_id: "bestbuds",
        num_nfts: 4200,
        image_url: "https://bafybeignvpjnqcrac7mt3o3kwtqmy4evwbambsw5rbd6jbzo44zbwqusfa.ipfs.dweb.link/461.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "gNaughty gNomes",
        dao_id: "gnaughtygnomes",
        num_nfts: 112,
        image_url: "https://www.arweave.net/a6oV8GSSZRABvDkmrBsLd3nuN2hjSI4kGTTPujmUHOY?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Daring Dragons",
        dao_id: "daringdragons",
        num_nfts: 5555,
        image_url: "https://www.arweave.net/QL5iObV9oDH8BQwtZcsrE1Bs30J836JzuWud1JZrHdg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Fishy Folk",
        dao_id: "fishyfolk",
        num_nfts: 2500,
        image_url: "https://www.arweave.net/WarYGO38gQwdwqJv1ioehGtlQy8jr51BCgl2d-2CpsQ?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Yapes",
        dao_id: "yapes",
        num_nfts: 722,
        image_url: "https://arweave.net/u40hRmfqIPP_lC8HSt1m87MTPTYd7UqaoEaBYZHRuic",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Battle Bois",
        dao_id: "battlebois",
        num_nfts: 112,
        image_url: "https://www.arweave.net/VxZVhmIGLh1eRWyPdr7oNDqm9_hr1iXbNdtNPB-5DIU?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "UGA NATION",
        dao_id: "uganation",
        num_nfts: 1111,
        image_url: "https://www.arweave.net/XR0AbF4iM_i6Htvxx4bhCrvX_ONFicZYlrkPFpBCHbM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Fellowship",
        dao_id: "thefellowship",
        num_nfts: 6543,
        image_url: "https://www.arweave.net/D0W5rzaFEQwwrM3Sw8M1gbXzfULRTWz6pNI52RgwpH0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "ThugMonkez",
        dao_id: "thugmonkez",
        num_nfts: 3333,
        image_url: "https://arweave.net/-tnrfOsJqTRE82TT1Z3tEZbCGJHAJzwy-lp1ihC3u7Q?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Monke Rejects",
        dao_id: "solanamonkerejects",
        num_nfts: 5991,
        image_url: "https://d1g1xztyt00aex.cloudfront.net/assets/3979.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "UNLOCK DEFI - GOLDEN TICKETS 1",
        dao_id: "unlockdefigoldentickets1",
        num_nfts: 5643,
        image_url: "https://arweave.net/WlZbCLVbA5Wty7LwovO7D4wkZLYS5SrIOVibL-EiRgM?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Safari Dition",
        dao_id: "safaridition",
        num_nfts: 500,
        image_url: "https://www.arweave.net/vInXdL_cH2WLCJ5U9j3xOrbanWf7NgjQIk-a2zUsjyM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Banana Solana",
        dao_id: "bananasolana",
        num_nfts: 2072,
        image_url: "https://www.arweave.net/lgoDCGwvF5XSfeAFv9XBiocagVBtDX72XiJYHjUZLKg?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Male HODL Whales",
        dao_id: "malehodlwhales",
        num_nfts: 3000,
        image_url: "https://www.arweave.net/wQvkdGI3b86ze8vP8UyOCxiOjuB5KbJb9EZwvwHOwv0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Friendly Frog Social Club",
        dao_id: "friendlyfrogsocialclub",
        num_nfts: 2121,
        image_url: "https://www.arweave.net/190MT0ICsOrCxYGFft693lkDOTGMwxEdSILnAXcmff0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Doge Capital",
        dao_id: "dogecapital",
        num_nfts: 1000,
        image_url: "https://www.arweave.net/h3xVWURnh4ofsFHBTns1ujhE9-xD_QwiwVJtwkJFJcE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Wolfiez",
        dao_id: "wolfiez",
        num_nfts: 900,
        image_url: "https://www.arweave.net/QIU7MfNyA5CGuQ0mdhFcRyMgwQQUIJsAtyJKP6mwlmM?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolFinity",
        dao_id: "solfinity",
        num_nfts: 500,
        image_url: "https://www.arweave.net/f39X8qFDch46s8PW3dZjBDfhMRWJvWCcekTjfusrl5k?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "MIM Swarmers",
        dao_id: "mimswarmers",
        num_nfts: 888,
        image_url: "https://www.arweave.net/IMa5bSOrG5D-0r-sM8lCRfq4h8La2mKiYlgquac-8ME?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Gloom Punk Club",
        dao_id: "gloompunkclub",
        num_nfts: 9000,
        image_url: "https://arweave.net/_7JXS4qCEz-mZkVrBLTFP8Vvg78RvTEEKOTKdt9L9SI",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Dexterity Art Club",
        dao_id: "dexterityartclub",
        num_nfts: 1031,
        image_url: "https://arweave.net/9TJXupOVA6t-v9zQkOShZ87a1INlnl1r8ZSUy8bdsK4",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bouncing Battle Blobs",
        dao_id: "bouncingbattleblobs",
        num_nfts: 1373,
        image_url: "https://www.arweave.net/Ganj1kJJ47F2arwWBPY0ukNf3JDEl8irKVbGLk8HatI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "NEONEXUS AIRDROPS",
        dao_id: "neonexusairdrops",
        num_nfts: 82785,
        image_url: "https://arweave.net/E6Hu5iVq_aHhk1um8Imz5t9l-I4JniAjZ8LcfZZsn94?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Baked Bots",
        dao_id: "bakedbots",
        num_nfts: 2420,
        image_url: "https://arweave.net/NLz3zc_C4FGd7cHkvpYfkF6XD8TgKp2is6tFYcL_GXA?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "skyline NFT",
        dao_id: "skylinenft",
        num_nfts: 1555,
        image_url: "https://www.arweave.net/wOjrG8SC5yaVWrRoqktvRaYORpyQSTrsONm3Sqbasto?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Infungibles",
        dao_id: "theinfungibles",
        num_nfts: 7777,
        image_url: "https://infungibles.io/si/3141.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Angry Bunny Club",
        dao_id: "angrybunnyclub",
        num_nfts: 5555,
        image_url: "https://www.arweave.net/sgxrQsGvvxgATMPw7h63dlSm1OXXPVQ0qcDxLI1mp3g?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bulls and Bears",
        dao_id: "bullsandbears",
        num_nfts: 2500,
        image_url: "https://prodbnbnft.s3.us-east-2.amazonaws.com/1233.png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Jungle Cats",
        dao_id: "junglecats",
        num_nfts: 5499,
        image_url: "https://www.arweave.net/jd_ylQaOhDpxnkvZe8SbiYd1DgW2WRdM4rlCRo3x5UE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Panda Social Club (Panda Fraternity)",
        dao_id: "solanapandasocialclubpandafraternity",
        num_nfts: 4980,
        image_url: "https://www.arweave.net/nCYPiqefgYm5uXV-OABEIAbZzsaB5P1oclhshfaKv20?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Artificial Irrelevants",
        dao_id: "artificialirrelevants",
        num_nfts: 1235,
        image_url: "https://www.arweave.net/mUIopYb5px-Urm4XdPkcd0SBxoLDUNN3HVYV-IHtd5s?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "The Catalina Whale Mixer",
        dao_id: "thecatalinawhalemixer",
        num_nfts: 5555,
        image_url: "https://www.arweave.net/1H-SBAbxW-76R0fuhT6kvkbxtqYH1ywWpvxte2Av5Is?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Hikaric Adventure",
        dao_id: "hikaricadventure",
        num_nfts: 3900,
        image_url: "https://bafybeidpbxismik37obgmt3egcw5g3neglfpfwf5ahvh37hxlgwdn56u3i.ipfs.dweb.link/2814.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Rude Golems",
        dao_id: "rudegolems",
        num_nfts: 8888,
        image_url: "https://www.arweave.net/VIQ2eigELr4EAG5LOVqpool7mfpqil5Wkbau8N7d4VI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Meekolony",
        dao_id: "meekolony",
        num_nfts: 10000,
        image_url: "https://www.arweave.net/Ujamv0EzBlJEM_-2S2ZtMiBABWPepFkYmVwm64rqItA?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "P2Pawnshop",
        dao_id: "p2pawnshop",
        num_nfts: 1895,
        image_url: "https://www.arweave.net/iNpOonn-EySHqmz7od6GsFn5Pzwt_YKCxbSw1ouYu3A?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Pumpfkin Statues",
        dao_id: "pumpfkinstatues",
        num_nfts: 900,
        image_url: "https://www.arweave.net/S6Vffti4RtLMg7weM9nUPRsZY40T1DDS0ZDozrs9i0M?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Dill The Seal",
        dao_id: "dilltheseal",
        num_nfts: 6666,
        image_url: "https://www.arweave.net/ujgXSC3_25ZheW0krjZkqMO_N4DvuiuCzPWWLeFVC04?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "K-Labs",
        dao_id: "klabs",
        num_nfts: 1999,
        image_url: "https://bafybeifygqh3nufiugp4wa5vmvke7idpbnbkmne5rhslgqkcaouzxju6py.ipfs.dweb.link/2.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Gorecats",
        dao_id: "gorecats",
        num_nfts: 1111,
        image_url: "https://arweave.net/ko9r-_6viZ-wnNrki6TJF7pIb4cy30OlE8d6_9t8O2Q?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solana Duck Business",
        dao_id: "solanaduckbusiness",
        num_nfts: 304,
        image_url: "https://www.arweave.net/DlprPdHWX3APpHZwxNANbCunzkPY4IzREEctGHaGpes?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Boopieverse The Historians",
        dao_id: "boopieversethehistorians",
        num_nfts: 5500,
        image_url: "https://www.arweave.net/6lIrwlaXxz3Fqr7bpjXtejyvhdQsUp6RcZxD6UpvUlk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Solpass",
        dao_id: "solpass",
        num_nfts: 900,
        image_url: "https://www.arweave.net/ZR9CviKSEOY-zPuEs_I64x2nKhhns_F38Xrl0u1CVao?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "GGSG Concordians",
        dao_id: "ggsgconcordians",
        num_nfts: 4096,
        image_url: "https://www.arweave.net/42RsemQzvTGA02YpUI2lL1qRZuP0F5ZVvlHwc-LXDL4?ext=jpeg",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "CASSETS Audio",
        dao_id: "cassetsaudio",
        num_nfts: 1043,
        image_url: "https://www.arweave.net/zUIppkQwDin3SgetEt6Xv2sqoklIgSIjjXib_2U94S4?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Bokun Geckos",
        dao_id: "bokungeckos",
        num_nfts: 394,
        image_url: "https://arweave.net/Vn4uFPtsbKGsR8Dtr5L-ANPbDYoUSdEyv1VBTtG02EI?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "PixelBands",
        dao_id: "pixelbands",
        num_nfts: 4444,
        image_url: "https://bafybeiak3sxkjhoiwzged2khc7aibpy7dihjc3krci66pkzjumysvxvjj4.ipfs.dweb.link/3046.gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Crazy Pickles",
        dao_id: "crazypickles",
        num_nfts: 333,
        image_url: "https://www.arweave.net/RxYEU638mwRKG9T7QaDWP_hMU5JT3nu-yUDPvsmVqPE?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Sockpass",
        dao_id: "sockpass",
        num_nfts: 8888,
        image_url: "https://arweave.net/bJK7GtnsItZxo35lNKMuBX5iNBC_t1J18i3N3PFZlKo?ext=gif",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Kyoudai Academy Solana Arcade Games",
        dao_id: "kyoudaiacademysolanaarcadegames",
        num_nfts: 888,
        image_url: "https://arweave.net/qtunrbveca3FT9lHrBZYc8V38K7nfcJwkeRpF26pDA0?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Social Sauna Club",
        dao_id: "socialsaunaclub",
        num_nfts: 2500,
        image_url: "https://www.arweave.net/5zkkX050DaO-3PK2zzF8ePlUIh5oagkoEBNTvYqJiuc?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Starbots Robot",
        dao_id: "starbotsrobot",
        num_nfts: 6000,
        image_url: "https://bafybeid3lweoffeg47ik5dq6m3tdvuxvv4gyzroptvmbbk2ip4qforbq7y.ipfs.dweb.link/4539.png?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "Alpaca Land",
        dao_id: "alpacaland",
        num_nfts: 215,
        image_url: "https://www.arweave.net/Y3DAIpMJ9sQGZ8mxibORTpb6T1_34xzb9C6Nkrxsv5Q?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "DoodleThugz",
        dao_id: "doodlethugz",
        num_nfts: 999,
        image_url: "https://www.arweave.net/4Spfd67X8iI7ejxS89l7CEylaJNI5UhSwSjXYic1QQk?ext=png",
        is_member: false,
        address: undefined,
    },

    {
        display_name: "SolGen",
        dao_id: "solgen",
        num_nfts: 7776,
        image_url: "https://arweave.net/Z2I1Maltry42UY9ZmUv-UmYYAEB3dudbT4l0nn6yugE?ext=png",
        is_member: false,
        address: undefined,
    },

];

export {verifiedDaos};
