import './style.scss';
import { TextareaAutosize, TextField, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import * as mirror_admin from "../../pic/live_utils/mirror_admindashboard"
import NavHeader from "../../components/NavHeader";
import { useAnchorWallet } from 'providers/adapters/core/react';

const AdminDashboard = () => {
  const [username, setUsername] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [version_num, setVersion_num] = useState<string>('');
  const [is_confirmed, setConfirmed] = useState<boolean>(false);
  const [version_content, setVersion_content] = useState<any[]>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const wallet = useAnchorWallet();

  const onChangeContent = (evt) => {
    setContent(evt.target.value)

  }
  const onChangeVersionNum = (evt) => {
    setVersion_num(evt.target.value)
  }

  const onSubmit = async () => {
    if (wallet) {
      const time_now = Math.floor(Date.now() / 1000);
      const result = await mirror_admin.insertNewContent_post(wallet.publicKey.toString(), content, version_num, time_now);
      if (result.success) {
        alert("Inserting is succeeded");
      } else {
        alert("Inserting is failed");
      }
      setContent('');
      setVersion_num('');
      setRefresh(true)
    } else {
      alert("Please connect your wallet");
    }
  }

  const onClickDelete = async (content, version_num) => {
    const result = await mirror_admin.deleteVersionContent(content, version_num);
    if (result.success) {
      alert("Deleting is succeeded");
      setRefresh(true)
    } else {
      alert("Deleting is failed");
    }

  }
  useEffect(() => {
    (async () => {
      setConfirmed(false);
      setRefresh(false);
      if (wallet) {
        const result_user = await mirror_admin.getUsers();
        if (result_user.success) {
          for (const user of result_user.data) {
            if (wallet.publicKey.toString() === user.username) {
              setConfirmed(true);
            }
          }
        }
      }
    })()
  }, [wallet])
  useEffect(() => {
    (async () => {
      setRefresh(false);
      const resultcontent = await mirror_admin.getVersions();
      if (resultcontent.success) {
        const data = resultcontent.data;
        setVersion_content(data);
      }
    })()
  }, [refresh])
  return (
    <div>
      <div className="main-page">
        <div>
          <NavHeader />
        </div>
        <div className="mainpage-title">
          Version Control Edit Page
        </div>
        {is_confirmed ?
          <div className="main-page-body">

            <div className="mainpage-content">
              <div className="textfield-wrapper">
                <TextField
                  id="version_control_edit"
                  label="Version Control Content"
                  multiline
                  rows={6}
                  variant="filled"
                  value={content}
                  style={{ width: '100%', backgroundColor: "white" }}
                  color="primary"
                  onChange={(evt) => onChangeContent(evt)}
                />
              </div>
              <div className="textfield-wrapper">
                <TextField
                  id="version_control_version"
                  label="Version"
                  multiline
                  rows={1}
                  value={version_num}
                  variant="filled"
                  style={{ width: '100%', backgroundColor: "white" }}
                  color="primary"
                  onChange={(evt) => onChangeVersionNum(evt)}
                />
              </div>

              <div className="submit-btn">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSubmit()}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="mainpage-version">
              <div className="version-control-body">
                <div className="version-control-title">
                  Version Control
                </div>
                <div className="version-control-content">
                  {version_content?.map((item, idx) => {
                    return (
                      <div className="version-control-box" key={idx} >
                        <div className="version-box-content">
                          {item.content}
                        </div>
                        <div className="version-box-num">
                          {item.version_num}
                        </div>
                        <div className="delete-btn">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => onClickDelete(item.content, item.version_num)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="version-control-bottom">
                  {/* abc */}
                </div>
              </div>
            </div>
          </div>
          : ''}
      </div>
    </div>
  )
}

export default AdminDashboard;