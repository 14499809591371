import { Keypair, Connection, PublicKey } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";

export async function get_latest_slot() {
  const rpc_url = "https://rpc.helius.xyz/?api-key=314468f7-11aa-4a18-8d0b-36a67bd6fb78";
  try {
    const connection = new Connection(rpc_url, 'confirmed');
    if (connection) {
      const slot = await connection.getSlot();
      return slot;
    }
  } catch (e) {
    console.log(e.toString());
  }
  return;
}

// export async function link_discord(owner_address: String) {
//   const BASE_URL = "https://discord.com/api/oauth2/authorize?response_type=code&client_id=995584198725357598&scope=identify&state=";
//   const BASE_URL_TAIL = "&redirect_uri=https%3A";
//   // WzxZ37tymTZ5UEJSXepwETLsSfkXFJSr2KQr1x5F92T&redirect_uri=https%3A//24wscwkbb2.execute-api.us-east-1.amazonaws.com/dev/discord_callback&prompt=consent,
//   const URL = BASE_URL + owner_address + BASE_URL_TAIL;
// }
