import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import "./style.scss";
import * as React from "react";
import { useAnchorWallet } from "providers/adapters/core/react";
import Giga_logo from "img/icons/gigadao-full-brand-cropped.png";
import Pitchdeck from "img/raidgame/books.png";
import Youtube from "img/raidgame/youtube.png";
import Comment from "img/raidgame/comment.png";
import Follow from "img/raidgame/follow.png";
import Like from "img/raidgame/like.png";
import Mentions from "img/raidgame/Mentions.png";
import Profile_picture from "img/raidgame/profile_picture.png";
import Quote_tweet from "img/raidgame/quote_tweet.png";
import Retweet from "img/raidgame/retweet.png";
import Whitepaper from "img/raidgame/Github2.png";
import Button from "components/common/Button";
import Heart_border from "img/raidgame/heart_border.png";
import Heart_full from "img/raidgame/heart_full.png";
import { clusterPath } from "utils/url";
import { ConnectWalletNavButton } from "components/ConnectWalletNavButton";
import * as utils from "../../utils/account_ownership";
import getProvider from "../../utils/getProvider";
import signMessage from "../../utils/signMessage";
import base58 from "bs58";
import * as mirror from "../../pic/live_utils/mirror_helpers"
import * as mirror_admin from "../../pic/live_utils/mirror_admindashboard"
const RaidGame: React.FC = (props) => {

  const [content, setContent] = useState<any[]>();
  const [likes, setLikes] = useState<any[]>(['']);
  const [tmp, setTmp] = useState();
  const [count_likes, setCountLikes] = useState<number>(0);
  const [user_exist, setUser_exist] = useState<boolean>(false)
  const [user_likes, setUser_likes] = useState<number>(0);
  const wallet = useAnchorWallet();

  const reward_list = [
    {
      title: "Like",
      content: Like
    },
    {
      title: "Comment",
      content: Comment
    },
    {
      title: "Quote Tweet",
      content: Quote_tweet
    },
    {
      title: "Retweet",
      content: Retweet
    },
    {
      title: "Mentions",
      content: Mentions
    },
    {
      title: "Profile Picture",
      content: Profile_picture
    },
    {
      title: "Raiders follow Raiders",
      content: Follow
    },];
  const info_icon_list = [
    {
      title: "Setup Tutorial",
      content: Youtube
    },
    {
      title: "Pitchdeck",
      content: Pitchdeck
    },

  ];

  const onClickLikes = async () => {
    try {

      if (wallet) {
        if (user_exist) {
          if (user_likes == 0) {
            await mirror_admin.updateLikes(wallet.publicKey.toString(), 1);
            setUser_likes(1);
            setCountLikes(count_likes + 1)
          } else {
            await mirror_admin.updateLikes(wallet.publicKey.toString(), 0);
            setUser_likes(0);
            setCountLikes(count_likes - 1)
          }
        } else {

        }
      } else {
        alert("Please connect your wallet");
      }
    } catch (e) {

      console.log(e);
    }
  }

  useEffect(() => {
    (async () => {
      setUser_exist(false);
      setUser_likes(0)
      if (wallet) {
        const result_likes = await mirror_admin.getLikes();
        if (result_likes.success) {
          const data = result_likes.data;
          let found_username = false;
          for (const item of data) {
            if (wallet.publicKey.toString() === item.username) {
              setUser_exist(true);
              setUser_likes(parseInt(item.likes));
              found_username = true;
              break;
            }
          }
          if (!found_username) {
            await mirror_admin.insertNewLikes(wallet.publicKey.toString(), 0);
            setUser_exist(true)
            setUser_likes(0);
          }
        }
      } else {
        setUser_exist(false);
      }
    })()
  }, [wallet])

  useEffect(() => {
    (async () => {
      const resultcontent = await mirror_admin.getVersions();
      const result_likes = await mirror_admin.getLikes();
      if (result_likes.success) {
        const data = result_likes.data;
        setLikes(data);
        let count = 0;
        for (const item of data) {
          if (parseInt(item.likes) == 1) {
            count++;
          }
        }
        setCountLikes(count);
      }
      if (resultcontent.success) {
        const data = resultcontent.data;
        setContent(data);
      }
    })()
  }, [])


  const onClickDashboard = () => {
    window.location.href = 'https://www.gigadao.io/maindashboard';
  }
  // const onClickWhitepaper = () => {
  //   window.open('https://gigadao.gitbook.io/enterprise-raid-game/');
  // }
  const onClickPitchdeck = () => {
    window.open('https://rgpd.gigadao.io/');
  }
  const onClickYoutube = () => {
    window.open(' https://youtu.be/ytsG1UduHz8');
  }
  const onClickInviteBtn = () => {

    // window.open('https://discord.com/api/oauth2/authorize?client_id=997093137560387725&permissions=2415971344&scope=bot%20applications.commands');
    window.open('https://discord.com/api/oauth2/authorize?client_id=1027453414952673345&permissions=2415971328&scope=bot%20applications.commands');
  }

  return (
    <div className="raidgame-main">
      <div className="raidgame-header">
        <div className="top-logo">
          <img src={Giga_logo} alt="Solana Explorer" />
        </div>
        <div className="top-nav-right">
          <Link to={clusterPath("/")}>
            <Button
              btn_type="common"
              btn_title="Dashboard"
            // onClick={() => onClickDashboard()}
            />
          </Link>
          <ConnectWalletNavButton />
        </div>
      </div>
      <div className="raidgame-body">

        <div className="raidgame-body-content">
          <div className="raidgame-title">
            RaidGame
          </div>
          <div className="raidgame-description">
            <div className="description-header1">
              Automate SPL Token payouts for your Twitter Raiders using the GigaDAO Raid Game
            </div>
            <div className="description-content">
              As always GigaDAO is using the freemium model so no matter how big or small your community is you don’t have to pay until you’re completely satisfied with its progress.
              <br />
              <br />
              You’ll get 500 free engagements every 30 days to play with and if you start to go over that we have the lowest package of 1 sol which gets you 100 engagements per raid for 30 days.
            </div>
            <div className="description-header2">
              What engagements can earn rewards
            </div>
            <div className="reward-box">
              {
                reward_list.map((item, index) => {
                  return (
                    <div className="each-reward" key={index}>
                      <div className="reward-img">
                        <img src={item.content} />
                      </div>
                      <div className="reward-title">
                        {item.title}
                      </div>
                    </div>
                  )
                })
              }


            </div>
            {/* <div className="description-header2">
              What engagements can earn rewards
            </div> */}
            <div className="info-box">
              <div className="each-info" onClick={() => onClickYoutube()}>
                <div className="info-img">
                  <img src={info_icon_list[0].content} />
                </div>
                <div className="info-title-underline" >
                  {info_icon_list[0].title}
                </div>
              </div>
              <div className="each-info" onClick={() => onClickPitchdeck()}>
                <div className="info-img">
                  <img src={info_icon_list[1].content} />
                </div>
                <div className="info-title-underline" >
                  {info_icon_list[1].title}
                </div>
              </div>
            </div>
            <div className="registration-box">
              <div className="registration-description">
                You can start raiding in the GigaDAO discord now. Register your Web 3 ID here to get started
              </div>
              <div className="div">
                <Link to={clusterPath("/registration")}>

                  <Button
                    btn_type="common-fixed-width-yellow"
                    btn_title="Register"
                  />
                </Link>
              </div>
            </div>
          </div>

        </div>
        <div className="raidgame-version-control">
          <div className="top-version-control">
            <div className="top-description">
              Invite the Raid Game bot to your discord here
            </div>
            <div className="top-invite-btn">
              <Button
                btn_type="common-fixed-width-yellow"
                btn_title="Invite"
                onClick={() => onClickInviteBtn()}
              />
            </div>
            <div className="top-likes">
              <div className="like-img" onClick={() => onClickLikes()}>
                {
                  user_likes ? <img src={Heart_full} /> : <img src={Heart_border} />
                }
              </div>
              <div className="likes-description">
                <div className="description-polygon">
                  {count_likes}&nbsp;likes
                </div>

              </div>
            </div>
          </div>
          <div className="version-control-body">
            <div className="version-control-title">
              Version Control
            </div>
            <div className="version-control-content">
              {content?.map((item, idx) => {
                return (
                  <div className="version-control-box" key={idx} >
                    <div className="version-box-content">
                      {item.content}
                    </div>
                    <div className="version-box-num">
                      {item.version_num}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="version-control-bottom">
              {/* abc */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default RaidGame;